const { default: strings } = require("./strings");

var stringMap = {};
stringMap[strings.About_App_Name] = "Mind+cartographie mentale";
stringMap[strings.TEMPLATE] = "modèle";
stringMap[strings.Mind_Edit_Main_Topic] = "Sujet de branche";
stringMap[strings.Global_Add_Free_Node_Tips] = "Cliquez sur l'écran pour sélectionner l'emplacement à ajouter";
stringMap[strings.Global_Add_Free_Chart_Node_Tips] = "Appuyez deux fois sur l'écran pour choisir un emplacement";
stringMap[strings.Global_Add_Relationship_Tips] = "Sélectionnez la cible à associer";
stringMap[strings.Global_Add_Target_Tips] = "Veuillez sélectionner un nœud pour ajouter des objets";
stringMap[strings.Mind_Mindmap_ConnectMap_Exist_Tips] = "Cet objet est déjà associé à d'autres cartes";
stringMap[strings.Mind_Edit_Main_Idea] = "Thème central";
stringMap[strings.Mind_Mindmap_Open_ConnectMap_No_Exist_Tips] = "La carte associée n'existe pas";
stringMap[strings.Mind_Style_Add_Icon_Priority] = "priorité";
stringMap[strings.Mind_Style_Add_Icon_Progress] = "horaire";
stringMap[strings.Mind_Style_Add_Icon_Stars] = "Star";
stringMap[strings.Mind_Style_Add_Icon_Week] = "semaine";
stringMap[strings.Mind_Style_Add_Icon_Head_Portrait] = "Avatar";
stringMap[strings.Establish] = "créer";
stringMap[strings.Create_From_Template] = "Créer à partir d'un modèle";
stringMap[strings.Common] = "Universel";
stringMap[strings.Function_List] = "liste de fonctions";
stringMap[strings.Recent_Updates] = "Récemment mis à jour";
stringMap[strings.The_Latest_To_Delete] = "Supprimé récemment";
stringMap[strings.Setting_Up] = "Nous contacter";
stringMap[strings.Usinghelp] = "Utiliser l'aide";
stringMap[strings.The_Download_Client] = "Télécharger la version client / mobile";
stringMap[strings.Global_Purchase] = "acheter";
stringMap[strings.Global_Login] = "connexion";
stringMap[strings.My_map] = "Ma carte";
stringMap[strings.Create_Map] = "Créer une carte";
stringMap[strings.Storm] = "Connaissances";
stringMap[strings.Mind_Double_Bubble_Edit_Main_Common_Characteristic] = "Fonctionnalités \ n communes";
stringMap[strings.Mind_Double_Bubble_Edit_Main_Characteristic] = "Fonctionnalité";
stringMap[strings.Mind_Organization_Edit_Main_Idea] = "Centre d'organisation";
stringMap[strings.Mind_Edit_Free_Topic] = "Thème gratuit";
stringMap[strings.Mind_Edit_Sub_Topic] = "Sous-thème";
stringMap[strings.Mind_Organization_Main_Topic] = "département";
stringMap[strings.Mind_Edit_Main_Topic] = "Sujet de branche";
stringMap[strings.Mind_Time_Edit_Main_Idea] = "gestion du plan";
stringMap[strings.Mind_Time_Edit_Head] = "temps";
stringMap[strings.Mind_Time_Edit_Title] = "plan";
stringMap[strings.Mind_Edit_Details] = "Description détaillée";
stringMap[strings.Checking_Data] = "vérification des données";
stringMap[strings.Setting_Information] = "Informations de réglage";
stringMap[strings.Sign_Out] = "se déconnecter";

//liste de fonctions
stringMap[strings.Create_a_Mind_Map] = "Créer une carte mentale";
stringMap[strings.Create_Mind_Map_1] = "【1】Créez quatorze cartes de mise en page (bidirectionnelle / droite / gauche / structure de l'organisation / graphique à bulles simples / double graphique à bulles / arborescence de gauche / arborescence de droite / arborescence bidirectionnelle / tableau / chronologie horizontale / chronologie verticale / chronologie S / pyramide) )";
stringMap[strings.Create_Mind_Map_2] = "【2】Les modèles peuvent être utilisés pour créer rapidement des cartes (un grand nombre de références de contenu de modèle)";
stringMap[strings.Create_Mind_Map_3] = "【3】Modifier neuf types de styles de ligne sur la carte";
stringMap[strings.Create_Mind_Map_4] = "【4】Modifier les neuf modifications de mise en page de la carte";
stringMap[strings.Create_Mind_Map_5] = "【5】Ajouter des nœuds enfants au nœud cible, ajouter des nœuds au même niveau (supérieur et inférieur)";
stringMap[strings.Create_Mind_Map_6] = "【6】Résumé: expliquez l'ajout de nœud unique / multi-nœud [Résumé]";
stringMap[strings.Create_Mind_Map_7] = "【7】Blocage: ajoutez [Blocage] à un seul nœud / plusieurs boîtes de nœuds de la carte pour la modularisation";
stringMap[strings.Create_Mind_Map_8] = "【8】Ajouter un nœud gratuit";
stringMap[strings.Create_Mind_Map_9] = "【9】Créez des associations de quatre styles de ligne entre tous les nœuds";
stringMap[strings.Create_Mind_Map_10] = "【10】Insérer des images (local + bibliothèque de matériaux), des icônes, des notes, des liens vers des nœuds";
stringMap[strings.Create_Mind_Map_11] = "【11】Copier / coller le nœud, copier / coller le style";
stringMap[strings.Create_Mind_Map_12] = "【12】Révocation";
stringMap[strings.Create_Mind_Map_13] = "【13】Multi-sélection, fonctionnement synchronisé de plusieurs nœuds";
stringMap[strings.Create_Mind_Map_14] = "【14】Graphique statistique insérable";
stringMap[strings.Create_Mind_Map_15] = "【15】La carte entière peut être enregistrée";
stringMap[strings.Create_Mind_Map_16] = "【16】Copier la carte";
stringMap[strings.Create_Mind_Map_17] = "【17】Vous pouvez copier la carte ou la carte entière sur la carte d'autres fichiers";
stringMap[strings.Create_Mind_Map_18] = "【18】Une carte peut être créée après le résumé";
stringMap[strings.Create_Mind_Map_19] = "【19】Lors de l'édition, vous pouvez basculer l'édition de la carte (côté ordinateur)";
stringMap[strings.Create_Mind_Map_20] = "【20】Carte d'édition du clavier Bluetooth: \ n \ t [Commande] + [a] Sélectionnez le nœud principal \ n \ t [Commande] + [←] [↑] [→] [↓] Changez la cible sélectionnée ou déplacez la carte \ n \ t [Commande] + [e] Modifier la cible \ n \ t [Commande] + [w] Créer un nœud enfant \ n \ t [Commande] + [s] Créer un nœud frère cible (haut) \ n \ t [Commande] + [d] Créer un nœud frère cible (ci-dessous) \ n \ t [Commande] + [q] Quitter la cible sélectionnée \ n \ t [Commande] + [-] [+] Zoom sur la carte ";

stringMap[strings.Beautify_The_Mind_Map] = "Embellissez la carte mentale";
stringMap[strings.Beautify_The_Mind_Map_1] = "【1】Définir la couleur d'arrière-plan de la carte mentale";
stringMap[strings.Beautify_The_Mind_Map_2] = "【2】Définissez l'épaisseur et la couleur de toute la ligne de la carte, qu'elle soit effilée ou non";
stringMap[strings.Beautify_The_Mind_Map_3] = "【3】Définir la couleur de remplissage du texte, la couleur de la bordure, les formes de la bordure 13, la largeur de la bordure, l'effet d'ombre";
stringMap[strings.Beautify_The_Mind_Map_4] = "【4】Définir la couleur et la largeur de tout ou partie de la carte";
stringMap[strings.Beautify_The_Mind_Map_5] = "【5】Définir la taille de la police du texte, gras, italique, supprimer le style";
stringMap[strings.Beautify_The_Mind_Map_6] = "【6】Définir la taille de l'image, la position, le style de la forme";
stringMap[strings.Beautify_The_Mind_Map_7] = "【7】Ajoutez sept catégories d'icônes (commun / 3D / étude / travail / personnes / animaux / nourriture) et définissez la taille / la position";
stringMap[strings.Beautify_The_Mind_Map_8] = "【8】Ajouter une référence de style en un clic à l'ensemble de la carte";
stringMap[strings.Beautify_The_Mind_Map_9] = "【9】Carte de gestion du groupe";
stringMap[strings.Beautify_The_Mind_Map_10] = "【10】Un arrière-plan personnalisé (bibliothèque de matériaux) peut être ajouté à l'ensemble de la carte";
stringMap[strings.Beautify_The_Mind_Map_9] = "【11】Des bordures ou arrière-plans individuels peuvent être ajoutés à un seul nœud (bibliothèque de matériaux)";
stringMap[strings.Beautify_The_Mind_Map_9] = "【12】La longueur du nœud peut être déplacée";


stringMap[strings.Map_Output] = "Sortie de la carte";
stringMap[strings.Map_Output_1] = "【1】enregistrer l'image";
stringMap[strings.Map_Output_2] = "【2】Enregistrer le PDF";
stringMap[strings.Map_Output_3] = "【3】Lien de partage";
stringMap[strings.Map_Output_4] = "【4】Partagez avec la tempête";
stringMap[strings.Map_Output_4] = "【5】imprimer";

stringMap[strings.Work_together] = "travailler ensemble";
stringMap[strings.Work_together_1] = "【1】Créer un groupe partagé (étiquette de groupe, annonce de groupe)";
stringMap[strings.Work_together_2] = "【2】Ajouter des membres d'un groupe partagé";
stringMap[strings.Work_together_3] = "【3】Plusieurs membres du groupe modifient la même carte ensemble";
stringMap[strings.Work_together_4] = "【4】Télécharger des fichiers partagés au sein du groupe (word / excel / pdf)";
stringMap[strings.Work_together_5] = "【5】Vous pouvez laisser un message dans le groupe";
stringMap[strings.Work_together_6] = "【6】Paramètres des autorisations de gestion des groupes partagés";
stringMap[strings.Work_together_7] = "【7】Dissoudre le groupe";

stringMap[strings.Work_together] = "Partage des connaissances (Storm)";
stringMap[strings.Community_Sharing_1] = "【1】Vous pouvez partager votre création avec la tempête, afin que plus de gens puissent voir";
stringMap[strings.Community_Sharing_1] = "【2】Vous pouvez sélectionner des balises pour afficher le contenu de la tempête associé";
stringMap[strings.Community_Sharing_1] = "【3】Vous pouvez aimer et commenter les travaux de tempête";

//Récemment mis à jour
stringMap[strings.Recently_Updated_Title_1] = "Créer un compte personnel";
stringMap[strings.Recently_Updated_Title_2] = "Base de connaissances des modèles de masse";
stringMap[strings.Recently_Updated_Title_3] = "Prend en charge plusieurs plates-formes";
stringMap[strings.Recently_Updated_Title_4] = "Personnalisez chaque détail";
stringMap[strings.Recently_Updated_Title_5] = "Bibliothèque de styles de styles personnalisés";
stringMap[strings.Recently_Updated_Title_6] = "Sortie de la carte";
stringMap[strings.Recently_Updated_Title_7] = "Stockage en ligne";
stringMap[strings.Recently_Updated_Title_8] = "Quinze types de cartes";


stringMap[strings.Recently_Updated_Content_1] = "Créez un compte personnel et enregistrez la carte créée avec le compte";
stringMap[strings.Recently_Updated_Content_2] = "La base de connaissances du modèle de masse est directement citée pour améliorer l'efficacité de l'apprentissage et du travail";
stringMap[strings.Recently_Updated_Content_3] = "Prise en charge de la production en ligne de la version Web, du terminal mobile IOS, du terminal Mac, du terminal mobile Android, de l'utilisation multiplateforme de la tablette Android";
stringMap[strings.Recently_Updated_Content_4] = "Peut embellir la conception du texte, de l'arrière-plan, des lignes, des icônes d'image, etc. selon les préférences personnelles";
stringMap[strings.Recently_Updated_Content_5] = "La bibliothèque de styles de styles individuels est directement citée, ce qui élimine les problèmes de correspondance des couleurs";
stringMap[strings.Recently_Updated_Content_6] = "Peut enregistrer des images, au format PDF, peut partager directement avec des amis";
stringMap[strings.Recently_Updated_Content_7] = "Prise en charge solide du stockage en nuage, vous n'avez plus à vous soucier de la perte de données";
stringMap[strings.Recently_Updated_Content_8] = "Bi-directionnel, droite, gauche, structure organisationnelle, graphique à bulle unique, graphique à double bulle, graphique à gauche, arbre à droite, arbre à double sens, tableau, chronologie horizontale, chronologie verticale, chronologie S, huit dispositions peuvent être modifiées à volonté";

//Récemment supprimé
stringMap[strings.Mind_Mindmap_Deleted_Title] = "Récemment supprimé";
stringMap[strings.Mind_Mindmap_Deleted_Recovery] = "restaurer";
stringMap[strings.Mind_Mindmap_Deleted_Forever] = "effacer";
stringMap[strings.Mind_Mindmap_Deleted_Filename] = "nom de fichier";
stringMap[strings.Mind_Mindmap_Deleted_Tips] = "Une fois supprimé ici, il sera définitivement supprimé";
stringMap[strings.Mind_Mindmap_Deleted_Operating] = "en fonctionnement";
stringMap[strings.Mind_Mindmap_Deleted_Selected] = "Rechercher des cartes récemment supprimées";
stringMap[strings.Mind_Mindmap_Deleted_Forever_Time] = "Supprimer la date";

//Nous contacter
stringMap[strings.Contact_Us_No_Public] = "le public";
stringMap[strings.Contact_Us_QQ_Group] = "Groupe QQ";
stringMap[strings.Contact_Us_Message_Board] = "babillard";
stringMap[strings.Contact_Us_Username] = "Nom";
stringMap[strings.Contact_Us_Email] = "boites aux lettres";
stringMap[strings.Contact_Us_Voicemail] = "Veuillez laisser vos précieux commentaires";
stringMap[strings.Contact_Us_Send_Email] = "Envoyer un e-mail";

//vérifier le message
stringMap[strings.Input_Username_isNull] = "Le nom ne peut pas être vide";
stringMap[strings.Input_Email_isNull] = "veuillez entrer votre email valide";
stringMap[strings.Input_Voicemail_isNull] = "Veuillez saisir le contenu";
stringMap[strings.Input_Introduction_isNull] = "L'introduction ne peut pas être vide";
stringMap[strings.Input_Title_isNull] = "Le titre ne peut pas être vide";

stringMap[strings.Input_Please_Enter_A_Valid_Phone_Number] = "s'il vous plaît entrer un numéro de téléphone valide";
stringMap[strings.Input_Please_Enter_Verification_Code] = "veuillez entrer le code de vérification!";
stringMap[strings.Input_Please_Enter_The_Password] = "S'il vous plaît entrer le mot de passe!";
stringMap[strings.Input_The_Password_Format_Is_Incorrect] = "Le format du mot de passe est incorrect!";
stringMap[strings.Input_Please_Enter_A_New_Password] = "Veuillez saisir un nouveau mot de passe!";
stringMap[strings.Input_Confirm_The_New_Password] = "Veuillez saisir le mot de passe de confirmation!";
stringMap[strings.Input_The_Passwords_Are_Inconsistent] = "Les mots de passe sont incohérents!";
stringMap[strings.Input_The_Please_Enter_The_Correct_Verification_Code] = "Veuillez entrer le bon code de vérification!";
stringMap[strings.Input_The_Verification_Code_Error] = "Erreur de code de vérification!";

//Utiliser l'aide
stringMap[strings.Mind_Usinghelp_1] = "Créer une carte mentale";
stringMap[strings.Mind_Usinghelp_2] = "Comment ajouter des nœuds";
stringMap[strings.Mind_Usinghelp_3] = "Créer un nœud gratuit";
stringMap[strings.Mind_Usinghelp_4] = "Faire une association";
stringMap[strings.Mind_Usinghelp_5] = "Embellissez la toile";
stringMap[strings.Mind_Usinghelp_6] = "Embellissez le texte";
stringMap[strings.Mind_Usinghelp_7] = "Insérer des notes, des liens";
stringMap[strings.Mind_Usinghelp_8] = "Insérer une icône";
stringMap[strings.Mind_Usinghelp_9] = "Insérer une image";
stringMap[strings.Mind_Usinghelp_10] = "Comment annuler la suppression";
stringMap[strings.Mind_Usinghelp_11] = "Exporter la carte mentale";
stringMap[strings.Mind_Usinghelp_12] = "Mise à niveau des membres";
stringMap[strings.Mind_Usinghelp_13] = "touche de raccourci";
stringMap[strings.Mind_Usinghelp_Understood] = "J'ai compris";
stringMap[strings.Mind_Usinghelp_14] = "Insérer une bibliothèque de matériaux";
//Utiliser help-2
stringMap[strings.Mind_Usinghelp_15] = "Nouvelle carte";
// stringMap[strings.Mind_Usinghelp_2] = "Ajouter un nœud";
// stringMap[strings.Mind_Usinghelp_4] = "Faire une association";
// stringMap[strings.Mind_Usinghelp_5] = "Embellissez la toile";
// stringMap[strings.Mind_Usinghelp_6] = "Embellissez le texte";
// stringMap[strings.Mind_Usinghelp_7] = "Ajouter des notes, des liens";
stringMap[strings.Mind_Usinghelp_17] = "Annuler l'opération, annuler";
stringMap[strings.Mind_Usinghelp_18] = "Faites glisser le nœud pour permuter";
stringMap[strings.Mind_Usinghelp_19] = "Alignement des nœuds";
stringMap[strings.Mind_Usinghelp_20] = "Changer le type de carte";
stringMap[strings.Mind_Usinghelp_21] = "Style de ligne de carte";
stringMap[strings.Mind_Usinghelp_22] = "Couleur d'arrière-plan de la carte";
stringMap[strings.Mind_Usinghelp_23] = "Définir la forme du nœud";
stringMap[strings.Mind_Usinghelp_24] = "Style de ligne de nœud";
stringMap[strings.Mind_Usinghelp_25] = "Paramètres d'image des nœuds";
stringMap[strings.Mind_Usinghelp_26] = "Paramètres de l'icône du nœud";
stringMap[strings.Mind_Usinghelp_27] = "Résumé de l'ajout de nœud";
stringMap[strings.Mind_Usinghelp_28] = "Sélection de trame de nœud unique";
stringMap[strings.Mind_Usinghelp_29] = "Bloc multi-nœuds";
stringMap[strings.Mind_Usinghelp_30] = "Nœud ajouter une bordure";
stringMap[strings.Mind_Usinghelp_31] = "Ajouter un graphique";
stringMap[strings.Mind_Usinghelp_32] = "Nœuds cachés et récupération";
stringMap[strings.Mind_Usinghelp_33] = "Nœud plusieurs opérations";
stringMap[strings.Mind_Usinghelp_34] = "Changer le style";
stringMap[strings.Mind_Usinghelp_35] = "Insérer un discours et une explication";
stringMap[strings.Mind_Usinghelp_36] = "Carte à image";
stringMap[strings.Mind_Usinghelp_37] = "Carte du document PDF";
stringMap[strings.Mind_Usinghelp_38] = "Imprimer la carte";
stringMap[strings.Mind_Usinghelp_39] = "Partage et visualisation de liens";
stringMap[strings.Mind_Usinghelp_40] = "Partage de carte à la tempête";
stringMap[strings.Mind_Usinghelp_41] = "Utilisation des dossiers";
stringMap[strings.Mind_Usinghelp_42] = "Supprimer et restaurer des cartes";
//Utiliser l'aide 3
stringMap[strings.Mind_Usinghelp_43] = "Des amis postulent pour rejoindre le groupe";
stringMap[strings.Mind_Usinghelp_44] = "Comment dissoudre un groupe";
stringMap[strings.Mind_Usinghelp_45] = "Télécharger des documents";
stringMap[strings.Mind_Usinghelp_46] = "Acceptez de rejoindre l'application";
stringMap[strings.Mind_Usinghelp_47] = "Coédition";
stringMap[strings.Mind_Usinghelp_48] = "Nouveau groupe de partage";
stringMap[strings.Mind_Usinghelp_49] = "Inviter les membres du groupe";

//tempête
stringMap[strings.Mind_Storm_Recommend] = "recommander";
stringMap[strings.Mind_Storm_Boilingpoint] = "Point d'ébullition";
stringMap[strings.Mind_Storm_Unfold] = "Se dérouler";
stringMap[strings.Mind_Storm_Putaway] = "Ranger";
stringMap[strings.Mind_Storm_Myclassification] = "Ma classification";
stringMap[strings.Mind_Storm_Recommended_Classification] = "Classification recommandée";
stringMap[strings.Mind_Storm_Click_Add_Category] = "Cliquez pour ajouter une catégorie";
stringMap[strings.Mind_Storm_No_Classification] = "Pas de classification";
stringMap[strings.Mind_Storm_No_Tinterested] = "pas intéressé";
stringMap[strings.Mind_Storm_Report] = "Signaler";
stringMap[strings.Mind_Storm_Views] = "Vues";

stringMap[strings.Mind_Storm_Pornographic] = "Vulgarité pornographique";
stringMap[strings.Mind_Storm_Advertising_Harassment] = "Harcèlement publicitaire";
stringMap[strings.Mind_Storm_Political_Religion] = "Religion politique";
stringMap[strings.Mind_Storm_Infringement] = "Violation (portrait, diffamation, plagiat, utilisation frauduleuse)";
stringMap[strings.Mind_Storm_Prohibited_Content] = "Contenu interdit";
stringMap[strings.Mind_Storm_Spread_Rumors] = "Répandre des rumeurs";
stringMap[strings.Mind_Storm_Report_Successful] = "Rapport réussi";

//Éditer
stringMap[strings.Global_Edit] = "Éditer";
stringMap[strings.Global_Cancel] = "Annuler";
stringMap[strings.Global_Ok] = "déterminer";
stringMap[strings.Global_Name] = "surnom";
stringMap[strings.Global_Introduction] = "introduction";
stringMap[strings.Global_Change_Avatar] = "Changer d'avatar";
stringMap[strings.Global_Local_Upload] = "Téléchargement local";

//renseignements personnels
stringMap[strings.User_Personal_information] = "renseignements personnels";
stringMap[strings.User_Member_Validity_Period] = "Période de validité de l'adhésion";
stringMap[strings.User_My_Share] = "Mon partage";
stringMap[strings.User_My_Collection] = "ma collection";
stringMap[strings.User_More_Message] = "Suite";
stringMap[strings.User_Deleted_Sotrm] = "Supprimer la tempête";
stringMap[strings.User_Folder_Rename] = "Renommer";
stringMap[strings.User_Folder_Rename_Root] = "Modifier les autorisations";
stringMap[strings.User_Folder_Rename_Root_Title] = "Modifier les autorisations";
stringMap[strings.User_Folder_Root_Public] = "Entièrement public";
stringMap[strings.User_Folder_Root_Viewonly] = "Le dossier public autorise uniquement l'affichage";
stringMap[strings.User_Folder_Root_Private] = "Privé";
stringMap[strings.User_Folder_Root_Public_Introduction] = "Les autres peuvent voir vos favoris sur votre page de profil";
stringMap[strings.User_Message_Link] = "relier";
stringMap[strings.User_New_Group] = "Nouveau groupe";
stringMap[strings.User_New_Group_Title] = "Créer un nouveau groupe";
stringMap[strings.User_New_Group_Style_Title] = "Type de fichier";
stringMap[strings.User_Share_Is_Null] = "Pas encore de partage!";
stringMap[strings.User_Deleted_Storm_Tips] = "Êtes-vous sûr de supprimer cette tempête?";
stringMap[strings.User_Remove_From_List] = "Retirer de la liste",
    stringMap[strings.User_Move_To] = "déménager à",
    stringMap[strings.User_Deleted_Link] = "Supprimer le lien"
stringMap[strings.User_Collction_Is_Null] = "Aucune collection"
stringMap[strings.User_Collection] = "Favoris"

//Boîte de connexion
stringMap[strings.User_Lolgin_Welcome_To_Login] = "Bienvenue dans Mind +"
stringMap[strings.User_Lolgin_Free_Registration] = "inscription gratuite"
stringMap[strings.User_Lolgin_Please_Enter_Phone_Number] = "Veuillez entrer le numéro de téléphone"
stringMap[strings.User_Lolgin_Please_Enter_Verification_Code] = "veuillez entrer le code de vérification"
stringMap[strings.User_Lolgin_Obtain] = "Obtenir"
stringMap[strings.User_Lolgin_Password_Login] = "Mot de passe de connexion"
stringMap[strings.User_Lolgin_Login] = "connexion"
stringMap[strings.User_Lolgin_Register_An_Account] = "Créer un compte"
stringMap[strings.User_Lolgin_Already_Have_An_Account] = "Vous avez déjà un compte"
stringMap[strings.User_Lolgin_To_Log_In] = "Ouvrir une session"
stringMap[strings.User_Lolgin_Please_Enter_Password] = "S'il vous plaît entrer le mot de passe"
stringMap[strings.User_Lolgin_Confirm_Password] = "Confirmez le mot de passe"
stringMap[strings.User_Lolgin_Registered] = "inscrit"
stringMap[strings.User_Lolgin_Account_Login] = "Connexion au compte"
stringMap[strings.User_Lolgin_Scan_Code_Login] = "Connexion par code de numérisation"
stringMap[strings.User_Lolgin_Forgot_Password] = "Mot de passe oublié"
stringMap[strings.User_Lolgin_Verification_Code_Login] = "Connexion par code de vérification"
stringMap[strings.User_Lolgin_Open_Mind_And_Scan_At_The_Top_Of_The_Setting_Interface] = "Ouvrez Mind + et scannez en haut de l'interface de configuration"
stringMap[strings.User_Lolgin_This_QR_Code_Expires_In_Five_Minutes_Please_Click] = "Ce code QR expire dans cinq minutes, veuillez cliquer"
stringMap[strings.User_Lolgin_Refresh] = "Rafraîchir"
stringMap[strings.User_Lolgin_Scanning_Is_Successful_Please_Confirm_On_Your_Phone] = "La numérisation est réussie, veuillez confirmer sur votre téléphone"
stringMap[strings.User_Lolgin_Whether_Yo_Authorize_Login] = "Autoriser ou non la connexion"
stringMap[strings.User_Lolgin_Please_Enter_New_Password] = "Veuillez saisir un nouveau mot de passe"
stringMap[strings.User_Lolgin_Reset] = "Réinitialiser"

//Ma carte
stringMap[strings.My_Mind_Search_Template_Type_Or_Template] = "Rechercher un type de modèle ou un modèle"
stringMap[strings.My_Mind_Date] = "Date"
stringMap[strings.My_Mind_Create_Folder] = "Créer le dossier"
stringMap[strings.My_Mind_Please_Enter_The_Folder_Name] = "Veuillez saisir le nom du dossier"
stringMap[strings.My_Mind_Please_Enter_A_New_Folder_Name] = "Veuillez saisir un nouveau nom de dossier"
stringMap[strings.My_Mind__No_Map_Yet_Go] = "Pas encore de carte, allez"
stringMap[strings.My_Mind_Create] = "créer"
stringMap[strings.My_Mind_Right] = "Droite!"
stringMap[strings.My_Mind_Open] = "allumer"
stringMap[strings.My_Mind_Type_Left_Right] = "Diagramme à double guide"
stringMap[strings.My_Mind_Type_Unilateral] = "Carte à sens unique"
stringMap[strings.My_Mind_Type_Bottom] = "Organigramme"
stringMap[strings.My_Mind_Type_Bubble] = "Graphique à bulles"
stringMap[strings.My_Mind_Type_Time_Vertical] = "Tableau des horaires"
stringMap[strings.My_Mind_Blank_Mind_Map] = "Carte mentale vierge"
stringMap[strings.My_Mind_All] = "Tout"
stringMap[strings.My_Mind_Views] = "Vues："

//Recherche de tempête
stringMap[strings.Storm_Search_How_Title] = "Tags populaires"
stringMap[strings.Storm_History_Search] = "Recherche historique"

//公共 header
stringMap[strings.Public_Header_Mind] = "Carte"
stringMap[strings.Public_Header_Outline] = "Contour"
stringMap[strings.Public_Header_Download_Apps] = "télécharger des applications"
stringMap[strings.Public_Header_Mobile_Version] = "Version mobile"
stringMap[strings.Public_Header_Creator] = "créateur："
stringMap[strings.Public_Header_View_Frequency] = "voir la fréquence："
stringMap[strings.Public_Header_Save] = "sauvegarder"
stringMap[strings.Header_Mind_Tips_Saved] = "Enregistré"
stringMap[strings.Header_Mind_Tips_Edit] = "Édité"
stringMap[strings.Header_Mind_Tips_Update_Saved] = "Mise à jour enregistrée"

//Menu d'édition de la carte
stringMap[strings.Mind_Edit_Revoke] = "Révoquer"
stringMap[strings.Mind_Edit_Restore] = "restaurer"
stringMap[strings.Mind_Edit_Subtopic] = "Sous-thème"
stringMap[strings.Mind_Edit_Top_Subtopic] = "Sous-thème"
stringMap[strings.Mind_Edit_Bottom_Subtopic] = "Sujet suivant"
stringMap[strings.Mind_Edit_Free_Node] = "Nœud gratuit"
stringMap[strings.Mind_Edit_Summary] = "Résumé"
stringMap[strings.Mind_Edit_Block] = "Bloquer"
stringMap[strings.Mind_Edit_Insert] = "Insérer"
stringMap[strings.Mind_Edit_Related] = "Associer"
stringMap[strings.Mind_Edit_Style] = "Style"
stringMap[strings.Mind_Edit_Recording] = "Enregistrement"
stringMap[strings.Mind_Edit_Insert_Picture] = "Image"
stringMap[strings.Mind_Edit_Insert_Icon] = "Icône"
stringMap[strings.Mind_Edit_Insert_Remarks] = "Note"
stringMap[strings.Mind_Edit_Insert_Link] = "lien"
stringMap[strings.Mind_Edit_Insert_Summary_Graph] = "Graphique"
stringMap[strings.Mind_Edit_Upload] = "Télécharger"
stringMap[strings.Mind_Edit_Right_Icon] = "icône"
stringMap[strings.Mind_Edit_Right_Style] = "style"
stringMap[strings.Mind_Edit_Audio_Decoding] = "Décodage..."
stringMap[strings.Mind_Edit_Audio_Duration] = "durée"
stringMap[strings.Mind_Edit_Delete_Audio_Tips] = "Êtes-vous sûr de supprimer l'audio enregistré?"
stringMap[strings.Mind_Edit_Join_Membership] = "rejoindre l'adhésion"
stringMap[strings.Mind_Edit_Upgrade] = "améliorer"
stringMap[strings.Mind_Edit_Upgrade_Item] = "Upgrade to Pro"
stringMap[strings.Mind_Edit_Join_Membership_Tips] = "Les membres peuvent avoir plus de temps d'enregistrement, maintenant pour passer à un membre?"

stringMap[strings.Mind_Edit_Choose_Material] = "Choisissez le matériau"
stringMap[strings.Mind_Edit_Drag_And_Drop_The_Picture_Here] = "Faites glisser et déposez l'image ici"
stringMap[strings.Mind_Edit_Select_locally] = "Sélectionnez localement"
stringMap[strings.Mind_Edit_Choose_From] = "Bibliothèque de matériaux"
stringMap[strings.Mind_Edit_Mine] = "mien"
stringMap[strings.Mind_Edit_My_Material] = "Mon matériel"
stringMap[strings.Mind_Edit_Recently_Used] = "Utilisé récemment"
stringMap[strings.Mind_Edit_Use] = "utiliser"
stringMap[strings.Mind_Edit_No_Material_Temporarily_Go_Add_It] = "Aucun matériel pour l'instant, allez l'ajouter!"
stringMap[strings.Mind_Edit_Select_Chart_Type] = "Sélectionnez le type de graphique"
stringMap[strings.Mind_Edit_Remarks] = "Remarques"
stringMap[strings.Mind_Edit_Remarks_Tips] = "Veuillez saisir les remarques"
stringMap[strings.Mind_Edit_Add] = "Ajouter à"
stringMap[strings.Mind_Edit_Please_Enter_The_Link] = "Veuillez entrer le lien"

//Modifier le menu de gauche de la page
stringMap[strings.Mind_Edit_Left_Menu_Save_Picture] = "enregistrer l'image"
stringMap[strings.Mind_Edit_Left_Menu_Export_Pdf] = "Exporter un PDF"
stringMap[strings.Mind_Edit_Left_Menu_Share_Links] = "Lien de partage"
stringMap[strings.Mind_Edit_Left_Menu_Share_The_Storm] = "Partagez avec la tempête"
stringMap[strings.Mind_Edit_Left_Menu_Printing] = "imprimer"
stringMap[strings.Mind_Edit_Left_Menu_Unlock_Full_Version] = "Débloquez la version complète"
stringMap[strings.Mind_Edit_Left_Menu_Help] = "aider"
stringMap[strings.Mind_Edit_Left_Menu_Save_Picture_Format] = "Enregistrer le format"
stringMap[strings.Mind_Edit_Left_Menu_Save_Picture_Png] = " png"
stringMap[strings.Mind_Edit_Left_Menu_Save_Picture_Jpg] = "jpg"
stringMap[strings.Mind_Edit_Left_Menu_Save_Picture_Webp] = "webp"
stringMap[strings.Mind_Edit_Left_Menu_Share_Map] = "Partager la carte"
stringMap[strings.Mind_Edit_Left_Menu_Share_Switch] = "Commutateur de partage"
stringMap[strings.Mind_Edit_Left_Menu_The_Link_Can_Be_Copied_And_Sent] = "Vous pouvez copier le lien et l'envoyer"
stringMap[strings.Mind_Edit_Left_Menu_Copy_Link] = "Copier le lien"
stringMap[strings.Mind_Edit_Left_Menu_Follow_The_Link] = "Si vous souhaitez modifier le lien, veuillez cliquer sur"
stringMap[strings.Mind_Edit_Left_Menu_Regenerate] = "régénérer"
stringMap[strings.Mind_Edit_Left_Menu_Link_Password] = "Lier le mot de passe："
stringMap[strings.Mind_Edit_Left_Menu_Allow_Save] = "Autoriser à enregistrer"
stringMap[strings.Mind_Edit_Left_Menu_Maximum_Number_Of_Views] = "Nombre maximum de vues"
stringMap[strings.Mind_Edit_Left_Menu_Infinite] = "illimité"
stringMap[strings.Mind_Edit_Left_Menu_Limit] = "limite"
stringMap[strings.Mind_Edit_Left_Menu_Second] = "Fois"
stringMap[strings.Mind_Edit_Left_Menu_Link_Validity] = "Période de validité du lien"
stringMap[strings.Mind_Edit_Left_Menu_1_Day] = "Un jour"
stringMap[strings.Mind_Edit_Left_Menu_3_Days] = "3 jours"
stringMap[strings.Mind_Edit_Left_Menu_7_Days] = "7 jours"
stringMap[strings.Mind_Edit_Left_Menu_Permanent] = "permanent"
stringMap[strings.Mind_Edit_Left_Menu_Copy_Content_And_Link] = "Copier le contenu et le lien "
stringMap[strings.Mind_Edit_Left_Menu_Regenerate_Tips] = "Voulez-vous vraiment vous régénérer? Le lien précédent sera invalide!"
stringMap[strings.Mind_Edit_Left_Menu_Share] = "Partagez-le"
stringMap[strings.Mind_Edit_Left_Menu_Custom_Category] = "Classification personnalisée："
stringMap[strings.Mind_Edit_Left_Menu_Allow_Comments] = "Autoriser ou non les commentaires"
stringMap[strings.Mind_Edit_Left_Menu_Edit_Add_Category] = "Modifier ajouter une catégorie"
stringMap[strings.Mind_Edit_Left_Menu_Membership_Rights] = "avantages membres"
stringMap[strings.Mind_Edit_Left_Menu_Support_Platform] = "Plateforme de support"
stringMap[strings.Mind_Edit_Left_Menu_Open_Membership] = "rejoindre l'adhésion"
stringMap[strings.Mind_Edit_Left_Menu_Activation_Code_Exchange] = "Utilisation du code d'activation"
stringMap[strings.Mind_Edit_Left_Menu_purchase_Tips] = "Droits des membres après l'achat"
stringMap[strings.Mind_Edit_Left_Menu_Payment_Type] = "Sélectionnez le mode de paiement"
stringMap[strings.Mind_Edit_Left_Menu_Activity_Price] = "Prix ​​de l'activité"
stringMap[strings.Mind_Edit_Left_Menu_Amount_Actually_Paid] = "Le montant effectivement payé"
stringMap[strings.Mind_Edit_Left_Menu_Immediate_Renewal] = "Renouveler maintenant"
stringMap[strings.Mind_Edit_Left_Menu_Terms_Of_Service] = "Conditions d'utilisation"
stringMap[strings.Mind_Edit_Left_Menu_Privacy_Policy] = "Politique de confidentialité"
stringMap[strings.Mind_Edit_Left_Menu_Activation_Code] = "Code d'activation:"
stringMap[strings.Mind_Edit_Left_Menu_Please_Input_Activation_Code] = "Veuillez saisir le code d'activation"
stringMap[strings.Mind_Edit_Left_Menu_Verify_Activation_Code] = "Vérifier le code d'activation"
stringMap[strings.Mind_Edit_Left_Menu_Select_Categories] = "Choisissez la catégorie"
stringMap[strings.Mind_Edit_Left_Menu_Main_Title_Cannot_Be_Empty] = "Le titre principal ne peut pas être vide"
stringMap[strings.Mind_Edit_Left_Menu_Coupon] = "coupon："
stringMap[strings.Mind_Edit_Left_Menu_Please_Enter_The_Coupon_Code] = "Veuillez saisir le code du coupon"
stringMap[strings.Mind_Confirm_Use_Coupons] = "Confirmer l'utilisation du coupon"
stringMap[strings.Mind_Edit_Left_Menu_Verification] = "vérification"
stringMap[strings.Mind_Edit_Left_Menu_Preferential_Amount] = "Prix ​​réduit"
stringMap[strings.Mind_Edit_Left_Menu_Pay_Immediately] = "payer immédiatement"
stringMap[strings.Mind_Edit_Left_Menu_Wx_Pay] = "WeChat Pay"
stringMap[strings.Mind_Edit_Left_Menu_Confirm_Exchange_Information] = "Confirmer les informations relatives à l'utilisation"
stringMap[strings.Mind_Edit_Left_Menu_Exchange_Code] = "Code de remboursement："
stringMap[strings.Mind_Edit_Left_Menu_Confirm_Exchange] = "échange"

//编辑页右侧菜单
stringMap[strings.Mind_Edit_Right_Menu_canvas] = "Toile"
stringMap[strings.Mind_Edit_Right_Menu_background_Color] = "Couleur de l'arrière plan"
stringMap[strings.Mind_Edit_Right_Menu_layout] = "disposition"
stringMap[strings.Mind_Edit_Right_Menu_Line_Style] = "Style de ligne"
stringMap[strings.Mind_Edit_Right_Menu_line_Color] = "Couleur de la ligne"
stringMap[strings.Mind_Edit_Right_Menu_Line_Width] = "Largeur de ligne"
stringMap[strings.Mind_Edit_Right_Menu_Line_Tapering] = "Dégressif"
stringMap[strings.Mind_Edit_Right_Menu_Theme_Font] = "Police du thème"
stringMap[strings.Mind_Edit_Right_Menu_Child_Font] = "Police enfant"
stringMap[strings.Mind_Edit_Right_Menu_text_Color] = "Couleur du texte"
stringMap[strings.Mind_Edit_Right_Menu_font_Size] = "taille de police"
stringMap[strings.Mind_Edit_Right_Menu_Font_Style] = "Le style de police"
stringMap[strings.Mind_Edit_Right_Menu_Border_Color] = "Couleur de la bordure"
stringMap[strings.Mind_Edit_Right_Menu_Fill_Color] = "La couleur de remplissage"
stringMap[strings.Mind_Edit_Right_Menu_Border_Shape] = "Forme de bordure"
stringMap[strings.Mind_Edit_Right_Menu_Border_Width] = "Largeur de la bordure"
stringMap[strings.Mind_Edit_Right_Menu_Show_Shadow_Effect] = "Afficher l'effet d'ombre (fonction d'abonnement)"
stringMap[strings.Mind_Edit_Right_Menu_Line_Color] = "Couleur de la ligne"
stringMap[strings.Mind_Edit_Right_Menu_Dotted_Line] = "ligne pointillée"
stringMap[strings.Mind_Edit_Right_Menu_Picture_Size] = "Taille de l'image"
stringMap[strings.Mind_Edit_Right_Menu_Picture_Location] = "Emplacement de l'image"
stringMap[strings.Mind_Edit_Right_Menu_Picture_Shape] = "Forme de l'image"
stringMap[strings.Mind_Edit_Right_Menu_Icon_Size] = "Taille de l'icône"
stringMap[strings.Mind_Edit_Right_Menu_Icon_Position] = "Position de l'icône"
stringMap[strings.Mind_Edit_Right_Menu_routine] = "conventionnel"
stringMap[strings.Mind_Edit_Right_Menu_priority] = "priorité"
stringMap[strings.Mind_Edit_Right_Menu_Progress] = "horaire"
stringMap[strings.Mind_Edit_Right_Menu_Stars] = "Star"
stringMap[strings.Mind_Edit_Right_Menu_Week] = "semaine"
stringMap[strings.Mind_Edit_Right_Menu_Head_Portrait] = "Avatar"
stringMap[strings.Mind_Edit_Right_Menu_3D] = "3D"
stringMap[strings.Mind_Edit_Right_Menu_Learning] = "Apprendre"
stringMap[strings.Mind_Edit_Right_Menu_Work] = "emplois"
stringMap[strings.Mind_Edit_Right_Menu_Characters] = "personnage"
stringMap[strings.Mind_Edit_Right_Menu_Animals] = "animal"
stringMap[strings.Mind_Edit_Right_Menu_Food] = "aliments"
stringMap[strings.Mind_Edit_Node_Menu_Copy_Style] = "Copier le style"
stringMap[strings.Mind_Edit_Node_Menu_Sticking_Style] = "Style de pâte"
stringMap[strings.Mind_Edit_Node_Menu_Modify_Shape] = "Modifier la forme"
stringMap[strings.Mind_Edit_Node_Menu_View_Picture] = "Voir l'image"
stringMap[strings.Mind_Edit_Node_Menu_Delete_Picture] = "Supprimer l'image"
stringMap[strings.Mind_Edit_Node_Menu_Copy] = "copie"
stringMap[strings.Mind_Edit_Node_Menu_Paste] = "Pâte"
stringMap[strings.Mind_Edit_Node_Menu_Delete_Line] = "Supprimer la ligne"
stringMap[strings.Mind_Edit_Node_Menu_Delete_Wrapping_Line] = "effacer"
stringMap[strings.Mind_Edit_Node_Menu_Edit_Remarks] = "Modifier les notes"
stringMap[strings.Mind_Edit_Node_Menu_Open_Link] = "Ouvrir le lien"
stringMap[strings.Mind_Edit_Node_Menu_Delete_Remarks] = "Supprimer la note"
stringMap[strings.Mind_Edit_Node_Menu_Delete_Link] = "Supprimer le lien"
stringMap[strings.Mind_Edit_Node_Menu_Delete_Icon] = "Supprimer l'icône"
stringMap[strings.Mind_Edit_Node_Menu_Icon_Style] = "Style d'icône"

//统计图编辑面板
stringMap[strings.Echart_Edit_Statistical] = "statistiques"
stringMap[strings.Echart_Edit_Value] = "valeur"
stringMap[strings.Echart_Edit_Color] = "Couleur"
stringMap[strings.Echart_Edit_Title] = "Titre"
stringMap[strings.Echart_Edit_Detailed_Description] = "Description détaillée"
stringMap[strings.Echart_Edit_Value_Sort] = "Sorte"
stringMap[strings.Echart_Edit_Value_Display_Type] = "Type d'affichage"
stringMap[strings.Echart_Edit_Value_Color_Scheme] = "schéma de coloration"
stringMap[strings.Echart_Edit_Value_Display_Size] = "Taille d'affichage"
stringMap[strings.Echart_Edit_Value_Title_Size] = "Taille du titre"
stringMap[strings.Echart_Edit_Value_Explain_Size] = "Expliquez la taille"
stringMap[strings.Echart_Edit_Value_Subscribe] = "abonnement"

//风暴标签页
stringMap[strings.StormBookTag_Subscribed] = "Abonné"
stringMap[strings.StormBookTag_List] = "Lister"

//风暴分享页
stringMap[strings.Storm_Share_Comment] = "commenter"
stringMap[strings.Storm_Share_Reply] = "Réponse"
stringMap[strings.Storm_Share_Let_Me_Comment_Too] = "Laisse moi aussi commenter~"
stringMap[strings.Storm_Share_Release] = "Libération"
stringMap[strings.Storm_Share_Main_Title] = "Titre principal"
stringMap[strings.Storm_Share_Subtitle] = "Sous-titre"
stringMap[strings.Storm_Share_Author] = "Auteur"
stringMap[strings.Storm_Share_Replies] = "réponses"
stringMap[strings.Storm_Share_Comment_Is_Null_Tips] = "Pas encore de commentaires, soyez le premier"
stringMap[strings.Storm_Share_Comment_Input_Is_Null_Tips] = "Veuillez entrer un commentaire ~"
stringMap[strings.Storm_Share_Delete_Comment_Tips] = "êtes-vous sûr de vouloir supprimer ce commentaire？"

//导图内部使用帮助
stringMap[strings.Mind_Usinghelp_Btn_1] = "touche de raccourci(windows)"
stringMap[strings.Mind_Usinghelp_Btn_2] = "touche de raccourci(mac)"
stringMap[strings.Mind_Usinghelp_Btn_3] = "la description"
stringMap[strings.Mind_Usinghelp_Btn_4] = "[Ctrl] + [D]"
stringMap[strings.Mind_Usinghelp_Btn_5] = "[Command] + [D]"
stringMap[strings.Mind_Usinghelp_Btn_6] = "Ajouter un nœud enfant"
stringMap[strings.Mind_Usinghelp_Btn_7] = "[Delet]"
stringMap[strings.Mind_Usinghelp_Btn_8] = "Supprimer le nœud actuel (notez qu'il ne peut pas être supprimé s'il est en état d'édition）"
stringMap[strings.Mind_Usinghelp_Btn_9] = "[Ctrl] + [E]"
stringMap[strings.Mind_Usinghelp_Btn_10] = "[Command] + [E]"
stringMap[strings.Mind_Usinghelp_Btn_11] = "Modifier le nœud actuel"
stringMap[strings.Mind_Usinghelp_Btn_12] = "[Ctrl] + [↑] [↓] [←] [→]"
stringMap[strings.Mind_Usinghelp_Btn_13] = "[Command] + [↑] [↓] [←] [→]"
stringMap[strings.Mind_Usinghelp_Btn_14] = "[Ctrl] + [+]"
stringMap[strings.Mind_Usinghelp_Btn_15] = "[Command] + [+]"
stringMap[strings.Mind_Usinghelp_Btn_16] = "Agrandir la carte"
stringMap[strings.Mind_Usinghelp_Btn_17] = "[Ctrl] + [-]"
stringMap[strings.Mind_Usinghelp_Btn_18] = "[Command] + [-]"
stringMap[strings.Mind_Usinghelp_Btn_19] = "Faites un zoom arrière sur la carte"
stringMap[strings.Mind_Usinghelp_Btn_20] = "[Ctrl] + [S]"
stringMap[strings.Mind_Usinghelp_Btn_21] = "[Command] + [S]"
stringMap[strings.Mind_Usinghelp_Btn_22] = "[Ctrl] + [Z]"
stringMap[strings.Mind_Usinghelp_Btn_23] = "[Command] + [Z]"
stringMap[strings.Mind_Usinghelp_Btn_24] = "[Ctrl] + [Shift] + [Z]"
stringMap[strings.Mind_Usinghelp_Btn_25] = "[Command] + [Shift] + [Z]"
stringMap[strings.Mind_Usinghelp_Btn_26] = "[Ctrl] + [B]"
stringMap[strings.Mind_Usinghelp_Btn_27] = "[Command] + [B]"
stringMap[strings.Mind_Usinghelp_Btn_28] = "Quitter l'édition"

//提示文字
stringMap[strings.Message_Tips_Login_Success] = "connexion réussie"
stringMap[strings.Message_Tips_Dlt_Success] = "supprimé avec succès"
stringMap[strings.Message_Tips_Report_Success] = "Rapport réussi"
stringMap[strings.Message_Tips_Copy_Success] = "Copier avec succès"
stringMap[strings.Message_Tips_Collction_Success] = "Succès de la collection"
stringMap[strings.Message_Tips_Avatar_Modification_Success] = "Avatar modifié avec succès"
stringMap[strings.Message_Tips_Signature_Modification_Success] = "Signature modifiée avec succès"
stringMap[strings.Message_Tips_Name_Modification_Success] = "Pseudo modifié avec succès"
stringMap[strings.Message_Tips_Modification_Success] = "Modifié avec succès"
stringMap[strings.Message_Tips_New_Profile_Success] = "Créer un nouveau dossier avec succès"
stringMap[strings.Message_Tips_Mobile_Success] = "Déplacé avec succès"
stringMap[strings.Message_Tips_Submit_Success] = "Soumis avec succès"
stringMap[strings.Message_Tips_Exchange_Success] = "Rédemption réussie"
stringMap[strings.Message_Tips_Please_Code] = "Veuillez saisir le code d'activation"
stringMap[strings.Message_Tips_Agree_To_Terms] = "Veuillez accepter la politique de confidentialité"
stringMap[strings.Message_Tips_Please_Sign_In] = "veuillez vous connecter"
stringMap[strings.Message_Tips_Share_Success] = "Partage réussi"
stringMap[strings.Message_Tips_Create_Success] = "Créé avec succès"
stringMap[strings.Message_Tips_Profile_Name_No_Null] = "Le nom du dossier ne peut pas être vide"
stringMap[strings.Message_Tips_Selecte_Success] = "Recherche terminée"
stringMap[strings.Message_Tips_Subscription_Success] = "Abonné avec succès"
stringMap[strings.Message_Tips_Unsubscribe_Success] = "Se désinscrire avec succès"
stringMap[strings.Message_Tips_Related_Content_Not_Found] = "Contenu associé introuvable"
stringMap[strings.Message_Tips_Max_Add_Class] = "Vous ne pouvez ajouter au plus"
stringMap[strings.Message_Tips_Registered_Success] = "注册成功"
stringMap[strings.Message_Tips_Scan_Code_Login_Failed] = "La connexion par code de scan a échoué"
stringMap[strings.Message_Tips_Share_Is_Null] = "Le lien de partage n'existe pas"
stringMap[strings.Message_Tips_Share_Link_Does_Not_Exist] = "Le fichier de téléchargement ne peut pas dépasser 3 Mo"
stringMap[strings.Message_Tips_Get_Permission_Successfully] = "A réussi à obtenir la permission"
stringMap[strings.Message_Tips_Membership_Required] = "Adhésion requise"
stringMap[strings.Message_Tips_Save_Success] = "Enregistré avec succès"
stringMap[strings.Message_Tips_Scan_Code_Login_Success] = "Connexion par code de numérisation réussie"
stringMap[strings.Message_Tips_Style] = "Classification"

//大纲模式
stringMap[strings.Outline_Menu_Indent] = "échancrure"
stringMap[strings.Outline_Menu_Decrease_Indent] = "Diminuer le retrait"

//帮助提示
stringMap[strings.Help_Background_Content] = "Fond de ligne lié, arrière-plan de ligne lié, arrière-plan de ligne lié, arrière-plan de ligne lié, arrière-plan de ligne lié, arrière-plan de ligne lié, arrière-plan de ligne lié, arrière-plan de ligne lié, arrière-plan de ligne lié,"
stringMap[strings.Help_Mind_Content] = "Vous pouvez créer des cartes avec différentes dispositions en fonction de vos préférences: carte à double guide, carte de gauche, carte de droite, organigramme, arbre bidirectionnel, arbre de gauche, arbre de droite et tableau"
stringMap[strings.Help_Share_Link_Content] = "Les liens de partage peuvent être partagés dans WeChat, QQ, Weibo et d'autres canaux sous forme de liens, et les amis peuvent voir le contenu"
stringMap[strings.Using_Templates] = "Utiliser des modèles"

//邀请码
stringMap[strings.User_Lolgin_Please_Enter_Invitation_Code] = "Veuillez saisir le code d'invitation"
stringMap[strings.User_Lolgin_Please_Correct_Enter_Invitation_Code] = "Veuillez saisir le bon code d'invitation"
stringMap[strings.User_Lolgin_Optional] = "Optionnel"

//使用素材

stringMap[strings.Cannot_Add_To_Node_Background] = "Non applicable à l'arrière-plan du nœud"
stringMap[strings.Cannot_Add_To_Img_Background] = "Ne convient pas à l'arrière-plan du texte"
stringMap[strings.Cannot_Add_To_Mind_Background] = "Ne convient pas pour envelopper l'arrière-plan de la ligne"
stringMap[strings.Mind_Edit_Node_Menu_Add_Encircle_Background] = "Ajouter une image d'arrière-plan"
stringMap[strings.Mind_Edit_Node_Menu_Delete_Encircle_Background] = "Supprimer l'image d'arrière-plan"
stringMap[strings.Mind_Add_Background_Image] = "Insérer une image d'arrière-plan"
stringMap[strings.Mind_Delete_Background_Image] = "Supprimer l'image d'arrière-plan"

stringMap[strings.Permanent_Member] = "Membre permanent"
stringMap[strings.Insufficient_Storage_Space] = "Espace de stockage insuffisant, mise à niveau de l'adhésion pour augmenter l'espace。"
stringMap[strings.Used_Space] = "stockage"
stringMap[strings.Current_Storage] = "Stockage actuel："
stringMap[strings.Used_Space_Upgrade] = "Mise à niveau de l'espace de stockage"
stringMap[strings.My_Mind_Is_Null_Tips] = "Mind+Carte mentale et notes Super mnémoniques, ajouter des ailes à la réflexion Créer, afficher, planifier, noter"
stringMap[strings.Insufficient_Storage_Space_Tempalte_Tips] = "Espace de stockage insuffisant, mettez à niveau les membres pour utiliser le modèle."

stringMap[strings.Group_Management] = "Grouper"
stringMap[strings.Save_Img_Fill_Full] = "Pavé"
stringMap[strings.Save_Img_Center_Tilt] = "Inclinaison centrale"
stringMap[strings.Save_Img_File_Full_Dark_Color] = "Couvert (sombre)"
stringMap[strings.Save_Img_Mind_Map_Watermarking] = "Mind+Filigrane de carte mentale"
stringMap[strings.Save_Img_Add_Watermark_To_Protect_Community_Creation] = "Ajouter un filigrane pour protéger la création de connaissances："
stringMap[strings.Save_Img_Watermark_Text] = "Texte du filigrane："
stringMap[strings.Save_Img_Watermark_Logo] = "LOGO en filigrane："
stringMap[strings.Save_Img_Watermark_Style] = "Style de filigrane："
stringMap[strings.Save_Img_Please_Input] = "Entrez s'il vous plait"
stringMap[strings.Save_Img_Chop_Tips] = "Ouvrez un membre pour profiter des droits de filigrane"
stringMap[strings.Public_Header_Mindmap_Mind] = "Mode carte"
stringMap[strings.Copy_Mind_Map] = "Copier la carte"
stringMap[strings.Font_Style_Bold] = "Audacieux"
stringMap[strings.Font_Style_Italics] = "Italique"
stringMap[strings.Font_Style_Delete_Line] = "Barré"
stringMap[strings.Remove_Any] = "Supprimer"

stringMap[strings.Mindmap_Review] = "Revue de la carte"
stringMap[strings.Mindmap_Review_Recently_Updated_Content_10] = "Approfondir l'impression, améliorer la mémoire, trier les idées et les informations efficacement"
stringMap[strings.Mindmap_Review_Content] = "L'examen de la carte fait référence à la lecture du processus d'édition, et le contenu est lu séquentiellement à partir du thème central. L'examen de la carte peut vous aider à effectuer l'inventaire et l'analyse du système et à trouver des idées et des failles. Au cours du processus de révision, vous pouvez trouver différentes inspirations. ."
stringMap[strings.Mindmap_Review_Speed] = "la vitesse"
stringMap[strings.Mindmap_Review_Word_Speed] = "Vitesse du texte"
stringMap[strings.Mindmap_Review_Background_Ratio] = "Ratio d'arrière-plan"
stringMap[strings.Mindmap_Review_Play_Order_Peer] = 'Priority peer content'
stringMap[strings.Mindmap_Review_Current_Playback_Node_Magnification] = "Agrandissement du nœud de lecture actuel"
stringMap[strings.Mindmap_Review_Node_Playback_Order] = "Ordre de lecture des nœuds"
stringMap[strings.Mindmap_Only_Play_Text] = 'Only play text'
stringMap[strings.Mindmap_Review_Tab_Lable] = "Thinking Review"
stringMap[strings.Mindmap_Speech_Tab_Lable] = "Demo mode"

stringMap[strings.Mind_Group_Picture_Upload] = "Téléchargement d'avatar"
stringMap[strings.Mind_Group_Upload_From_Local] = "Téléchargement local"
stringMap[strings.Mind_Group_Mind_Mapping] = "cartographie mentale"
stringMap[strings.Mind_Group_Information] = "Les données"

stringMap[strings.Mind_Group_Invite_New_Members] = "Invitez de nouveaux membres"
stringMap[strings.Mind_Group_Please_Enter_Your_Cell_Phone_Number] = "Veuillez entrer le numéro de téléphone"
stringMap[strings.Mind_Group_Cancel] = "Annuler"
stringMap[strings.Mind_Group_Confirm] = "déterminer"
stringMap[strings.Mind_Group_The_Phone_Number_Cannot_Be_Blank] = "Le numéro de téléphone ne peut pas être vide"
stringMap[strings.Mind_Group_Invite_The_Successful] = "Invitation réussie"


stringMap[strings.Mind_Group_Shared_Group_Membership] = "Membres du groupe partagé"
stringMap[strings.Mind_Group_Maximum_Number_Of_Shared_Group_Members] = "Nombre maximum de membres du groupe partagé" 
stringMap[strings.Mind_Group_Membership_Limit] = "Limite de membres" 
stringMap[strings.Mind_Group_Current_Member_Limit] = "Limite de membres actuelle" 
stringMap[strings.Mind_Group_Upgrade_Member_Limit] = "Mettre à niveau la limite de membre"
stringMap[strings.Mind_Group_Members_Of_The_Search] = "Rechercher des membres"
stringMap[strings.Mind_Group_Creator] = "créateur"
stringMap[strings.Mind_Group_Administrator] = "administrateur"
stringMap[strings.Mind_Group_Number_Of_People] = "gens"
stringMap[strings.Mind_Group_Selected] = "choisi"
stringMap[strings.Mind_Group_Member] = "membre"
stringMap[strings.Mind_Group_Check_All] = "tout sélectionner"
stringMap[strings.Mind_Group_Remove] = "Supprimer"
stringMap[strings.Mind_Group_Invite_New_Members] = "Invitez de nouveaux membres"
stringMap[strings.Mind_Group_Delete_Members] = "Supprimer le membre"
stringMap[strings.Mind_Group_Please_Select_The_Member_To_Delete] = "Veuillez sélectionner le membre à supprimer "
stringMap[strings.Mind_Group_Make_Sure_You_Want_To_Remove_The_User_From_The_Group] = "Voulez-vous vraiment supprimer des utilisateurs de ce groupe？"
stringMap[strings.Mind_Group_Add_As_Administrator] = "Ajouter un gestionnaire"
stringMap[strings.Mind_Group_Accomplish] = "effectuer"
stringMap[strings.Mind_Group_Compile] = "Éditer"
stringMap[strings.Mind_Group_Group_Owner] = "Seigneur"
stringMap[strings.Mind_Group_Add_Admin] = "Ajouter un gestionnaire"
stringMap[strings.Mind_Group_Group_ID] = "Identifiant de groupe"
stringMap[strings.Mind_Group_Group_Number] = "No de groupe"
stringMap[strings.Mind_Group_Name] = "Nom"
stringMap[strings.Mind_Group_Please_Enter_A_Shared_Group_Name] = "Veuillez saisir le nom du groupe de partage"
stringMap[strings.Mind_Group_Introduce] = "introduction"
stringMap[strings.Mind_Group_Please_Give_A_Brief_Introduction_To_Shared_Groups] = "Veuillez présenter brièvement le groupe de partage"
stringMap[strings.Mind_Group_Announcement] = "Annonce"
stringMap[strings.Mind_Group_Shared_Group_Tag] = "Libellé du groupe partagé"
stringMap[strings.Mind_Group_Custom_Tag] = "Étiquette personnalisée"
stringMap[strings.Mind_Group_Save] = "sauvegarder"
stringMap[strings.Mind_Group_The_Group_Name_Cannot_Be_Empty] = "Le nom du groupe ne peut pas être vide"
stringMap[strings.Mind_Group_Establish] = "créer"
stringMap[strings.Mind_Group_Allows_Mapfolder_Creation] = "Autoriser la création de cartes / dossiers"
stringMap[strings.Mind_Group_Allows_Editing_Of_Maps] = "Autoriser la modification des cartes"
stringMap[strings.Mind_Group_Holder] = "Tout"
stringMap[strings.Mind_Group_Only_The_Administrator] = "Administrateur uniquement"
stringMap[strings.Mind_Group_Not_Allow] = "Interdit"
stringMap[strings.Mind_Group_Allows_Map_Deletion] = "Autoriser à supprimer la carte"
stringMap[strings.Mind_Group_Allows_Discussion_Boards_To_Post_Messages] = "Autoriser les forums à envoyer des messages"
stringMap[strings.Mind_Group_Export] = "Exportation"
stringMap[strings.Mind_Group_Allows_You_To_Save_Images_PDF] = "Autoriser l'enregistrement de l'image / PDF"
stringMap[strings.Mind_Group_Open_Group_Watermark] = "Activer le filigrane de groupe"
stringMap[strings.Mind_Group_When_Turned_On_The_Mind_Map_Will_Be_Printed_With_A_Group_Logo] = "Après ouverture, le logo du groupe sera imprimé sur la carte mentale"
stringMap[strings.Mind_Group_Allow_Saving_Maps] = "Autoriser à enregistrer des cartes"
stringMap[strings.Mind_Group_Save_It_To_My_Map] = "Enregistrer sur ma carte"
stringMap[strings.Mind_Group_Whether_Sharing_To_Storm_Is_Allowed] = "Autoriser ou non le partage à la tempête"
stringMap[strings.Mind_Group_Join_The_Way] = "Comment adhérer"
stringMap[strings.Mind_Group_Whether_To_Invite_New_Members] = "Autoriser ou non les nouveaux membres à être invités"
stringMap[strings.Mind_Group_Whether_To_Allow_Search_Group_Numbers_To_Be_Added] = "Autoriser ou non le numéro du groupe de recherche à se joindre"
stringMap[strings.Mind_Group_Whether_The_Message_Needs_To_Be_Validated_When_Joining] = "Avez-vous besoin de vérifier le message lors de l'inscription"
stringMap[strings.Mind_Group_Shared_Group_Data] = "Informations de groupe partagées"
stringMap[strings.Mind_Group_Authority_Management] = "gestion d'autorité"
stringMap[strings.Mind_Group_Quit_Sharing_Of_Group] = "Quitter le groupe partagé"
stringMap[strings.Mind_Group_Confirm_To_Exit_The_Shared_Group] = "Êtes-vous sûr de quitter le groupe de partage?"
stringMap[strings.Mind_Group_The_Dissolution_Of_Group] = "Dissoudre le groupe"
stringMap[strings.Mind_Group_The_Files_Will_Be_Deleted_After_Disbanding_The_Group_Confirm_Disbanding] = "Les fichiers seront supprimés après la dissolution du groupe. Voulez-vous vraiment dissoudre?"
stringMap[strings.Mind_Group_Return_To_Home_Page] = "retour à la page d'accueil"
stringMap[strings.Mind_Group_Join_The_Group] = "Rejoindre le groupe"
stringMap[strings.Mind_Group_Please_Enter_The_Group_Number] = "Veuillez saisir le numéro du groupe / nom du groupe à rechercher"
stringMap[strings.Mind_Group_Apply_To_Join] = "Demandes d'adhésion"
stringMap[strings.Mind_Group_Join] = "Rejoindre"
stringMap[strings.Mind_Group_Verification] = "vérification"
stringMap[strings.Mind_Group_Please_Enter_A_Validation_Message] = "Veuillez saisir un message de vérification"
stringMap[strings.Mind_Group_The_Content_Cannot_Be_Empty] = "le contenu ne peut pas être vide"
stringMap[strings.Mind_Group_Name] = "Nom du groupe partagé"
stringMap[strings.Mind_Group_Please_Enter_A_Shared_Group_Name] = "Veuillez saisir le nom du groupe de partage"
stringMap[strings.Mind_Group_Introduction] = "Présentation du groupe de partage"
stringMap[strings.Mind_Group_Group_Indication] = "No de groupe"
stringMap[strings.Mind_Group_The_Creator_Is_Lazy_And_Hasnt_Been_Introduced_Yet] = "Le créateur est paresseux, je ne l'ai pas encore présenté~"
stringMap[strings.Mind_Group_Shared_Group_Announcement] = "Annonce du groupe de partage"
stringMap[strings.Mind_Group_No_Announcement] = "Aucune annonce"
stringMap[strings.Mind_Group_Click_On] = "Cliquez pour agrandir"
stringMap[strings.Mind_Group_Click_On_The_Hidden] = "Cliquez pour cacher"
stringMap[strings.Mind_Group_Altogether] = "Le total"
stringMap[strings.Mind_Group_The_Group_Number_Was_Copied_Successfully] = "Copier le numéro de groupe avec succès"
stringMap[strings.Mind_Group_Stick] = "Haut"
stringMap[strings.Mind_Group_There_Is_A_New_Content] = "Nouveau contenu"
stringMap[strings.Mind_Group_Cancel_The_Top] = "Dissocier"
stringMap[strings.Mind_Group_Setting_Up] = "Mettre en place"
stringMap[strings.Mind_Group_Usinghelp] = "Utiliser l'aide"
stringMap[strings.Mind_Group_Set_The_Message] = "Message de groupe"
stringMap[strings.Mind_Group_Apply_To_Join] = "Demandes d'adhésion"
stringMap[strings.Mind_Group_Invite_To_Join] = "Inviter à rejoindre"
stringMap[strings.Mind_Group_Consent] = "Je suis d'accord"
stringMap[strings.Mind_Group_Turn_Down] = "Refuser"
stringMap[strings.Mind_Group_Label] = "étiqueter"
stringMap[strings.Mind_Group_Please_Enter_A_Shared_Group_Label_Name] = "Veuillez saisir un nom d'étiquette de groupe partagé"
stringMap[strings.Mind_Group_Invite_Friends_And_Colleagues_To_Create_And_Share_Community] = "Invitez vos amis et collègues à co-créer et partager du contenu de connaissances"
stringMap[strings.Mind_Group_Immediately_Create] = "Créer maintenant"
stringMap[strings.Mind_Group_Immediately_To_Join] = "Adhérer maintenant"

stringMap[strings.Currently_Selected] = "Attention actuelle"
stringMap[strings.LabelManagement] = "Gestion des étiquettes"
stringMap[strings.NoAttention] = "Pas d'attention"
stringMap[strings.Mindmap_Review_Play] = "Jouer"

stringMap[strings.Mind_Group_Joined] = "rejoint"
stringMap[strings.Mind_Group_Download_App] = "Téléchargez l'APP"
stringMap[strings.Mind_Group_No_Content] = "pas encore de contenu"
stringMap[strings.Mind_Group_Individual] = "UNE"
stringMap[strings.Mind_Group_Message_Area] = "Zone de message"
stringMap[strings.Mind_Group_No_Message] = "Pas encore de commentaires, allez-y"
stringMap[strings.Mind_Group_Leave_Message] = "laisser un message"
stringMap[strings.Mind_Group_Bar] = "Bar"
stringMap[strings.Mind_Group_See_More] = "voir plus"
stringMap[strings.Mind_Group_Enter_Invitation_Code] = "Entrez le code d'invitation"
stringMap[strings.Mind_Group_Apply_To_Join_Sharing_Group] = "Appliquer pour rejoindre un groupe partagé"
stringMap[strings.Reasons_For_Application_0_30_Words] = "Raison de la candidature (0-30 mots)"
stringMap[strings.Mind_Group_Send_Out] = "envoyer"
stringMap[strings.Mind_Group_Join_Successfully] = "Inscription réussie"
stringMap[strings.Mind_Group_Application_Sent_Successfully] = "Application envoyée avec succès"

stringMap[strings.Mind_Group_Save_The_Mapping] = "Enregistrer la carte"
stringMap[strings.Mind_Group_Update_Record] = "Modifier l'enregistrement"

stringMap[strings.Mind_Edit_Page_Switch_Mapping] = "Changer de carte"
stringMap[strings.Mind_Edit_Page_Mind_Retrospect] = "Revue de la carte"
stringMap[strings.Mind_Edit_Page_A_User_Is_Already_Editing_The_Map] = "Un utilisateur existant est en train de modifier la carte"
stringMap[strings.Mind_Edit_Page_Password] = "le mot de passe"

stringMap[strings.Mind_Edit_Page_Sharing_Group] = "Groupe partagé"
stringMap[strings.Mind_MBean] = "Haricots M"
stringMap[strings.Mind_My_MBean] = "Mon m haricot"
stringMap[strings.Alipay] = "Alipay"
stringMap[strings.Alipay_Payment] = "Payer avec Ali-Pay"
stringMap[strings.Please_Pay_On_The_Payment_Page] = "Veuillez vous rendre sur la page de paiement pour payer"
stringMap[strings.WeChat] = "Wechat"
stringMap[strings.Mind_Shooping_Total] = "le total"
stringMap[strings.Mind_Shooping_Confirm_Payment] = "confirmer le paiement"
stringMap[strings.Mind_Shooping_Redeem_Now] = "Échangez maintenant"

stringMap[strings.Mind_Whether_Confirm_Payment_Purchase] = "Confirmer le paiement pour l'achat"
stringMap[strings.Mind_Tips] = "rapide"
stringMap[strings.Mind_Your_M_Bean_Insufficient_Whether_Go__Purchase_M_Bean] = "Vos haricots M ne suffisent pas, voulez-vous acheter des haricots M?"

stringMap[strings.Mind_share_scan_code] = "Mind+Scannez le code"

stringMap[strings.Mind_Play_Resume_Tips_Do_You_Want_To_Go_Back_To_Where_It_Was_Last_Played] = "Est-il restauré à l'endroit où il a été joué pour la dernière fois?"
stringMap[strings.Mind_Play_Number_Of_Playable_Times_Remaining] = "Temps de jeu restants"

stringMap[strings.Group_Mind_Play_The_File_Will_Not_Exist] = "Le fichier sera complètement supprimé, êtes-vous sûr de le dissoudre?"
stringMap[strings.Group_Mind_Up_One_Level] = "niveau supérieur"
stringMap[strings.Group_Mind_No_Documents] = "Aucun fichier"
stringMap[strings.Group_Mind_PaymentSuccessful] = "paiement réussi"

stringMap[strings.Mind_Edit_Page_Sharing_Group_introduce] = "Édition collaborative par des personnes, synchronisation en temps réel de plusieurs terminaux, partage de documents, amélioration de l'efficacité de la collaboration en équipe"

stringMap[strings.Mind_Edit_Subscript] = "Indice"
stringMap[strings.Mind_Edit_Text_Location] = "Position du texte"
stringMap[strings.Mind_Mind_Import_Into_A_Shared_Group] = "Importer dans un groupe partagé"

stringMap[strings.Standard_Colors] = "Couleur standard"
stringMap[strings.More_Colors] = "plus de couleurs"
stringMap[strings.Reset_Password] = "réinitialiser le mot de passe"
stringMap[strings.Account_Password_Login] = "Connexion par mot de passe du compte"
stringMap[strings.Authentication_code_Login_registration] = "Code de vérification connexion / enregistrement"
stringMap[strings.One_End_Creation_Multi_End_Synchronization] = "Création à une extrémité, synchronisation à plusieurs extrémités"
stringMap[strings.Rich_And_Diverse_Mapping_Structure] = "Structure de carte riche et diversifiée"
stringMap[strings.Massive_And_ExquisiteTemplateLibrary] = "Bibliothèque de modèles exquis massive"
stringMap[strings.Map_Review_Play] = "Révision de la carte - Lecture"
stringMap[strings.CommunityLearningAndSharingPlatform] = "Plateforme d'échange et d'apprentissage des connaissances"

stringMap[strings.Mind_Mind_Upgrade_Storage_Space] = "Mettre à niveau l'espace de stockage"
stringMap[strings.Mind_Mind_Let_Me_Comment_Too] = "Laisse moi aussi commenter"
stringMap[strings.Mind_Mind_No_Message_Notifications_Are_Announced_At_This_Time] = "Aucune notification d'actualités"
stringMap[strings.Failed_To_Get_Recording_Device] = "Impossible d'obtenir le périphérique d'enregistrement"

stringMap[strings.Search_Pagination] = "Rechercher la pagination"
stringMap[strings.Please_Enter_A_Paging_Name] = "Veuillez saisir le nom de la page"
stringMap[strings.Operation_Settings] = "Paramètres d'opération"
stringMap[strings.Shortcut_key_Settings] = "Paramètres des touches de raccourci"
stringMap[strings.Press_Key_Combination] = "Veuillez appuyer sur la combinaison de touches requise"
stringMap[strings.Mind_Reduction] = "Réduction"
stringMap[strings.Double_Click_Add_Free_Node] = "Double-cliquez sur l'espace vide pour ajouter un nœud libre"
stringMap[strings.Shortcut_Conflict] = "Conflit de touche de raccourci"
stringMap[strings.Select_Main_Node] = "Sélectionner le nœud principal"
stringMap[strings.Telephone] = "Téléphone"
stringMap[strings.Microblog] = "Microblog"
stringMap[strings.Address] = "Adresse"
stringMap[strings.Company_Address] = "Block B, Building 21, No. 1158 Zhongxin Road, Songjiang District, Shanghai (Songjiang Lingang Caohejing)"
stringMap[strings.Mind_Archive] = "Archive"
stringMap[strings.Mind_Bookshelf] = "Bibliothèque"
stringMap[strings.Search_Archive] = "Rechercher dans la bibliothèque"
stringMap[strings.Modify_Archive_Order] = "Modifier l'ordre de la bibliothèque"
stringMap[strings.Modification_Order] = "Ordre de modification"
stringMap[strings.Please_Enter_Archive_Name] = "Veuillez saisir le nom de la bibliothèque"
stringMap[strings.Message_Tips_Archive_Name_No_Null] = "Le nom de l'étagère ne peut pas être vide"
stringMap[strings.Mind_Edit_Delete_Archive_Tips] = "Voulez-vous vraiment supprimer cette bibliothèque ?"
stringMap[strings.Mind_Edit_Delete_Bookcase_Tips] = "Voulez-vous vraiment supprimer cette bibliothèque ?"
stringMap[strings.Mind_Edit_Delete_Map_Tips] = "Êtes-vous sûr de vouloir supprimer cette carte ?"
stringMap[strings.Mind_Edit_Confirm] = "Confirmer"
stringMap[strings.Mind_Import_Map] = "Importer la carte"
stringMap[strings.Mind_Bookcase] = "Bibliothèque"
stringMap[strings.Added_Successfully] = "Ajouté avec succès"
stringMap[strings.Message_Tips_Bookcase_Name_No_Null] = "Le nom de la bibliothèque ne peut pas être vide"

stringMap[strings.Mind_Association_Map] = "Carte d'association"
stringMap[strings.Mind_confirm_Association_Map] = "Êtes-vous sûr d'associer cette carte ?"
stringMap[strings.Mind_Association_Map_See] = "Afficher la carte des associations"
stringMap[strings.Mind_Association_Map_Delete] = "Supprimer la carte d'association"

stringMap[strings.Mind_Edit_Right_Menu_Correlation_Line] = "Structure de ligne de corrélation"
stringMap[strings.Mind_Edit_Right_Menu_Correlation_Line_Start] = "Début de la ligne de corrélation"
stringMap[strings.Mind_Edit_Right_Menu_Correlation_Line_End] = "La fin de la ligne de corrélation"

stringMap[strings.Share_Group_To_Storm] = "S'il faut partager le groupe à la tempête"
stringMap[strings.Apply_To_Join_Storm_Platform] = "Sur la plate-forme Storm, d'autres personnes voient et postulent pour rejoindre, remue-méninges avec les amateurs de connaissances communes"
stringMap[strings.Pay_To_Goin] = "Payer pour rejoindre"
stringMap[strings.Pay_To_Goin_Need_Mbeans] = "M beans doivent être payés lors de l'adhésion"
stringMap[strings.Mbean_Can_Be_Realized] = "Mbean réalisé"

stringMap[strings.Mind_Visit] = "Visit"
stringMap[strings.Mind_Details] = "Détails"
stringMap[strings.Mind_See] = "Affichage"
stringMap[strings.Earn_Mbeans] = "Gagner des Mbeans"
stringMap[strings.Mind_Explain] = "Description"
stringMap[strings.Mind_Parenting] = "Parenting"
stringMap[strings.Mind_Internet] = "Internet"
stringMap[strings.Mind_Psychology] = "Psychologie"
stringMap[strings.Mind_literature] = "Littérature"
stringMap[strings.Mind_Technology] = "Technologie"
stringMap[strings.Mind_Workplace] = "Lieu de travail"
stringMap[strings.Mind_Life] = "Vie"
stringMap[strings.Mind_Subject] = "Sujet"
stringMap[strings.Hot_Group] = "Groupe chaud"
stringMap[strings.Open_Group] = "Ouvrir le groupe"
stringMap[strings.Mind_Group_Used] = "Utilisé"

stringMap[strings.Mind_Click_Select] = "Cliquez pour sélectionner"
stringMap[strings.Mind_Double_Click_Enter] = "Double-cliquez pour entrer"
stringMap[strings.Search_Shared_Groups] = "Rechercher des groupes partagés"
stringMap[strings.Search_Storm] = "Rechercher Tempête"
stringMap[strings.Members_Save_Free] = "Les membres économisent gratuitement"
stringMap[strings.Bank_Card_Account_Name] = "Veuillez saisir le nom de votre compte de carte bancaire"
stringMap[strings.Bank_Card_No] = "Numéro de carte bancaire"
stringMap[strings.Please_Enter_Your_Bank_Card_No] = "Veuillez saisir votre numéro de carte bancaire"
stringMap[strings.Branch_Sub_Branch_Information] = "Informations sur la succursale"
stringMap[strings.Branch_Sub_Branch_Example] = "Par exemple : Hangzhou Branch Chengxi Sub-branch"
stringMap[strings.Application_For_Withdrawal] = "Demande de retrait"
stringMap[strings.Withdrawal_Amount] = "Montant du retrait"
stringMap[strings.Withdrawal] = "Retirer"
stringMap[strings.Current_Revenue] = "Revenu actuel"
stringMap[strings.Recharge] = "Recharger"
stringMap[strings.Current_Total_MBeans] = "Nombre total actuel de MBeans"
stringMap[strings.Withdrawal_Status] = "Statut de retrait"
stringMap[strings.Insufficient_Withdrawal_Amount] = "Montant du retrait insuffisant"
stringMap[strings.Load_More] = "Charger plus"
stringMap[strings.Loading] = "Chargement"
stringMap[strings.It_Is_Over] = "C'est fini"
stringMap[strings.Default_Label] = "Libellé par défaut"
stringMap[strings.Selected_Label] = "Libellé sélectionné"
stringMap[strings.Current_Location] = "Emplacement actuel"
stringMap[strings.MGold_Introduction] = "Vous gagnez de l'or grâce à Mind+"
stringMap[strings.MGold_Details] = "Votre groupe cloud payant, il y a des cartes de connaissances, du matériel d'apprentissage, etc. dans le groupe. Si les utilisateurs rejoignent le paiement M beans, 50% d'entre eux seront convertis en M gold, et M gold plus plus de 50 peuvent être extraits"
stringMap[strings.Cloud_Group_Introduction] = "Partagez votre groupe, les autres peuvent le voir sur la page tempête"
stringMap[strings.Cloud_Group_Details] = "Votre groupe cloud payant. Il y a des cartes de connaissances, du matériel d'apprentissage, etc. dans le groupe. Si les utilisateurs rejoignent le paiement M beans, 50% d'entre eux seront convertis en M gold, et M gold plus plus de 50 peuvent être retirés"
stringMap[strings.Share_Storm_Introduction] = "Partagez votre carte avec la communauté Storm, afin que plus de personnes puissent apprendre et explorer vos connaissances"
stringMap[strings.Share_Storm_Details] = "Si vous activez l'utilisation de beans M pour enregistrer des cartes, d'autres utilisateurs peuvent utiliser votre carte de connaissances et vous obtiendrez également 50 % des beans M. Les beans M peuvent être utilisés pour acheter des membres et devenir payants groupes, vous pouvez également payer pour utiliser les cartes d'autres personnes."

stringMap[strings.Linked_Successfully] = "Lié avec succès"
stringMap[strings.Cancel_Archiving] = "Annuler l'archivage"
stringMap[strings.Please_Select_Archive_Location] = "Veuillez sélectionner l'emplacement de l'archive"
stringMap[strings.The_Search_Result_Is_Empty] = "Les résultats de la recherche sont vides"

stringMap[strings.Customize_A_Style] = "Les non-membres ne peuvent personnaliser qu'un seul style"
stringMap[strings.Adds_Current_Guide_Style_Library] = "Ajouter le style de carte actuel à la bibliothèque de styles"
stringMap[strings.Trial_Version] = "Version d'essai"
stringMap[strings.Not_Supported_At_The_Moment] = "Non actuellement pris en charge"
stringMap[strings.Mind_File] = "Fichier"
stringMap[strings.Mind_Import] = "Importer"
stringMap[strings.Importing] = "Importation"

stringMap[strings.Mind_Edit_Insert_Video] = "Insérer une vidéo"
stringMap[strings.Mind_Edit_Drag_And_Drop_The_Video_Here] = "Faites glisser et déposez la vidéo ici"
stringMap[strings.Message_Tips_Video_Upload_Time_Limit] = "La durée de téléchargement de la vidéo ne peut pas dépasser 60 s"
stringMap[strings.Mind_Edit_Node_Menu_View_Video] = "Voir la vidéo"
stringMap[strings.Mind_Edit_Node_Delete_View_Video] = "Supprimer la vidéo"
stringMap[strings.Message_Tips_Share_Video_Does_Not_Exist] = "La vidéo téléchargée ne peut pas dépasser 10 Mo"
stringMap[strings.Mind_Upload_Video_Restrictions] = "Prend en charge uniquement le téléchargement de vidéos au format MP4, jusqu'à 120s et 10M en taille"
stringMap[strings.Mind_Video_Size] = "Taille de la vidéo"
stringMap[strings.Mind_Edit_Left_Menu_Export_Word] = "Exporter le mot"
stringMap[strings.Mind_Edit_Left_Menu_Export_TXT] = "Exporter le TXT"
stringMap [strings.Mind_Edit_Left_Menu_Export_Save_Cloud] = "Cloud Save"
stringMap [strings.Mind_Edit_Left_Menu_Export_No_Save_Cloud] = "Not synchronized"
stringMap [strings.Mind_Edit_Left_Menu_Export_Save_As] = "Save as"
stringMap[strings.Mind_Edit_Left_Menu_Export_Group_Not_Supporte_Save_As] = "The sharing group does not support saving as. You can save the file to [Recent Creation] first, and then to local"
stringMap[strings.Export_Failed] = "Échec de l'exportation"
stringMap[strings.Add_File] = "Ajouter un fichier"
stringMap[strings.Drag_The_File_Directly_To_Upload] = "Faites glisser le fichier directement pour le télécharger"
stringMap[strings.Supported_Imported_File_Types] = "Types de fichiers actuellement pris en charge pour l'importation : Word (uniquement .docx), XMind, .txt"
stringMap[strings.No_Permission_Do_This] = "Vous n'êtes pas autorisé à effectuer cette opération"

stringMap[strings.Upgrade_To_Professional] = "Mettre à niveau vers l'édition professionnelle"
stringMap[strings.Non_Members_Insert_Videos] = "Les non-membres ne peuvent insérer des vidéos que 24 heures après l'inscription"
stringMap[strings.Upload_Failed] = "Le téléchargement a échoué"
stringMap[strings.Upload_Failed_Title_Message] = "Le téléchargement a échoué, veuillez cliquer pour confirmer, supprimez les images restantes qui n'ont pas été téléchargées"

stringMap[strings.Mind_Edit_Members_Use] = "Les membres utilisent"
stringMap[strings.Invitation_Code] = "Code d'invitation"
stringMap[strings.Student_Certification] = "Certification de l'étudiant"
stringMap[strings.I_Get_It] = "Je le ferai, sautez le guide"
stringMap[strings.Welcome_To_Mind] = "Bienvenue au jeune maître à rejoindre Mind+, commençons le voyage de la réflexion !"
stringMap[strings.Mind_Before] = "Premier"
stringMap[strings.Try_A_Map] = "Essayer une carte"
stringMap[strings.Click_Create] = "Cliquez pour créer"
stringMap[strings.Click_Create_A_Mind_Map] = "Cliquez pour créer une carte mentale"
stringMap[strings.Mind_About] = "À propos"
stringMap[strings.See_Checking_Data] = "Afficher les informations personnelles"
stringMap[strings.Modify_Checking_Data] = "Modifier les informations personnelles"
stringMap[strings.Mind_Shear] = "Cisaillement"

stringMap[strings.Mind_Style_Empty] = "Aucun style favori ou personnalisé"
stringMap[strings.Mind_Branch_Free_Layout] = "Mise en page libre de la branche"
stringMap[strings.Mind_Branch_Free_Layout_explain] = "Glisser librement la branche"
stringMap[strings.Mind_Free_Theme_Drag_Dnd_Snap] = "Gratuit Thème Drag Snap"

stringMap [strings.Select_A_Node] = "Pumili ng isang paksa"
stringMap [strings.Create_Child_Node] = "Lumikha ng tema ng bata"
stringMap [strings.Add_A_Nice_Image_To_The_Node] = "Susunod ay magdagdag kami ng isang maganda sa tema ng bata"
stringMap [strings.Click_Icon_Panel] = "I-click ang panel ng icon"
stringMap [strings.Select_An_Icon_You_Like] = "Pumili ng isang icon na gusto mo"
stringMap [strings.Replace_The_Mind_Map_With_A_New_One] = "Susunod ay papalitan natin ang mind map ng bago"
stringMap [strings.Select_The_Central_Theme] = "Piliin ang gitnang tema"
stringMap [strings.Switch_To_Canvas_Panel] = "Lumipat sa Canvas Panel"
stringMap [strings.Click_Layout] = "Mag-click sa Layout"
stringMap [strings.Choose_A_Layout_You_Like] = "Pumili ng isang layout na gusto mo"
stringMap [strings.Mind_Map_Save_Share] = "Binabati kita, matagumpay kang nakagawa ng isang magandang mapa ng isip! I-save natin ito at ibahagi ito sa iyong mga kaibigan ~"
stringMap [strings.Click_Save_Picture] = "I-click upang i-save ang larawan"
stringMap [strings.Click_OK] = "Click OK"
stringMap [strings.Congratulations_On_Completing_The_Novice_Guide] = "Binabati kita sa pagkumpleto ng gabay ng baguhan ~"
stringMap [strings.Upload_Successed] = "Matagumpay na na-upload"

stringMap [strings.Mind_Edit_Node_Menu_Quick_Style] = "Mabilis na Estilo"
stringMap [strings.Mind_Extremely_Important] = "Lubhang mahalaga"
stringMap [strings.Mind_Important] = "Mahalaga"
stringMap [strings.Mind_Commonly] = "Pangkalahatan"
stringMap [strings.Mind_Ordinary] = "Ordinaryo"
stringMap [strings.Member_Exclusive] = "Ang tampok na ito ay eksklusibo sa mga miyembro. Nais mo bang i-upgrade ang iyong pagiging miyembro?"
stringMap [strings.Member_Exclusive_Color_Scheme] = "Ang color scheme na ito ay eksklusibo sa mga miyembro. Nais mo bang i-upgrade ang iyong pagiging miyembro?"
stringMap [strings.Mind_Enlarge] = "Palakihin"
stringMap [strings.Mind_Narrow] = "Makitid"
stringMap [strings.Mind_Center] = "Nakasentro"
stringMap [strings.Mind_Full_Screen] = "Buong Screen"

stringMap [strings.Mind_Association_Node] = "Association Node"
stringMap[strings.Mind_Version_Number] = "Numéro de version"
stringMap[strings.Mind_Copy_Rights] = "Copyright ©2021 Shanghai Yushu Technology Co., Ltd. Tous droits réservés."

stringMap[strings.Global_Edit_Task] = "Modifier la tâche"
stringMap[strings.Global_Detele_Task] = "Supprimer la tâche"
stringMap[strings.Mind_Extremely_Urgent] = "C'est urgent"
stringMap[strings.Mind_Urgent] = "Urgent"
stringMap[strings.Mind_Lower] = "Réduction"
stringMap[strings.Mind_Task_Information] = "Informations sur la tâche"
stringMap[strings.Mind_Start_Date] = "Heure de début"
stringMap[strings.Mind_End_Date] = "fin"
stringMap[strings.Mind_person_Charge] = "Personne exécutive"
stringMap[strings.Mind_colse] = "Fermer"
stringMap[strings.Mind_Enter_Names_Separated_By_Commas] = "Entrez les noms, séparez plusieurs noms par des virgules"
stringMap[strings.Mind_Please_Select_An_Start_Date] = "Veuillez sélectionner une date de début"
stringMap[strings.Mind_Please_Select_An_End_Date] = "Veuillez sélectionner la date de fin"
stringMap[strings.Mind_The_Task_Leader_Cannot_Be_Empty] = "Ajouter un exécuteur"
stringMap[strings.Mind_The_End_Time_Less_Start_Time] = "L'heure de fin ne peut pas être inférieure à l'heure de début"
stringMap[strings.Mind_Are_You_Sure_To_Delete_This_Task] = "Êtes-vous sûr de supprimer cette tâche ?"
stringMap[strings.Mind_Start_Later] = "Commencer plus tard"
stringMap[strings.Mind_Over] = "Over"
stringMap[strings.Mind_In_Progress_Remaining] = "En cours... restant"
stringMap[strings.Mind_In_Progress_Time] = "En cours... dans le temps"
stringMap[strings.Mind_level] = "Niveau d'achèvement"
stringMap[strings.Mind_Completed] = "Terminé"
stringMap[strings.Display_Mode] = "Mode d'affichage"
stringMap[strings.Default_Sort] = "Tri par défaut"
stringMap[strings.Default_Sort] = "Tri par défaut"
stringMap[strings.Edit_Time] = "Modifier l'heure"

stringMap[strings.Mind_Year] = "."
stringMap[strings.Mind_Month] = "."
stringMap[strings.Mind_Day] = " "
stringMap [strings.Mind_Day_Day] = "Jour"
stringMap[strings.Mind_Hours] = "Heures"
stringMap[strings.Mind_Minutes] = "Minutes"

stringMap[strings.Mind_SubjectLine_Space] = "Subject space"
stringMap[strings.Mind_SonSubjectLine_Space] = "Son space"
stringMap[strings.Mind_SubjectLine_H] = "Subject space H"
stringMap[strings.Mind_SubjectLine_Vertical_Space] = "Subject space V"
stringMap[strings.Mind_SonSubjectLine_H] = "Son space H"
stringMap[strings.Mind_SonSubjectLine_Vertical_Space] = "Son space V"

stringMap[strings.Mind_Edit_Latex] = "Modifier LaTeX"
stringMap[strings.Mind_Detele_Latex] = "Supprimer LaTeX"
stringMap[strings.Mind_Are_You_Sure_To_Delete_This_LaTeX] = "Êtes-vous sûr de vouloir supprimer la formule LaTeX"
stringMap[strings.Mind_LaTeX_Expression] = "Expression LaTeX"
stringMap[strings.Mind_LaTeX_Formula_Display_Area] = "Zone d'affichage de la formule"

stringMap[strings.Mind_LaTeX_Add_Template] = "Ajouter au modèle"
stringMap[strings.Mind_LaTeX_Common_Template] = "Modèle commun"
stringMap[strings.Mind_LaTeX_Used_Help_1] = "Les fonctions LaTeX sont divisées en quatre modules, à savoir"
stringMap[strings.Mind_LaTeX_Used_Help_1_1] = "La zone de formule génère des graphiques de formule correspondants basés sur des expressions"
stringMap[strings.Mind_LaTeX_Used_Help_1_2] = "Expression area-enter LaTeX expression"
stringMap[strings.Mind_LaTeX_Used_Help_1_3] = "Valeurs de chaîne de zone de symbole correspondant aux symboles dans les champs communs"
stringMap[strings.Mind_LaTeX_Used_Help_1_4] = "Modèles couramment utilisés - certaines formules par défaut, vous pouvez personnaliser les formules via 'Ajouter au modèle', et vous pouvez supprimer les formules personnalisées spécifiées en déplaçant la souris."
stringMap[strings.Mind_LaTeX_Used_Help_2] = "Les graphiques de formule doivent être générés et enregistrés et utilisés selon les règles de grammaire LaTeX correctes"
stringMap[strings.Mind_LaTeX_Used_Help_3] = "Comment modifier la couleur ?"
stringMap[strings.Mind_LaTeX_Used_Help_3_1] = "Sélectionnez la zone de texte spécifiée et cliquez sur la couleur dans le symbole de style pour modifier la couleur du texte,"
stringMap[strings.Mind_LaTeX_Used_Help_3_2] = "Par exemple, pour colorer a+b dans x=a+b, sélectionnez simplement a+b et cliquez sur le bloc de couleur dans le symbole de style,"
stringMap[strings.Mind_LaTeX_Used_Help_3_3] = "À ce moment, l'expression deviendra x={\\color{#FF0000}a} changer la coloration a en a+b"
stringMap[strings.Mind_LaTeX_Quick_Title_2] = "Lettres grecques"
stringMap[strings.Mind_LaTeX_Quick_Title_3] = "Mathématiques"
stringMap[strings.Mind_LaTeX_Quick_Title_4] = "Symbole de style"
stringMap[strings.Mind_LaTeX_Quick_Title_5] = "Séparateur"
stringMap[strings.Mind_LaTeX_Quick_Title_6] = "caractère variable"
stringMap[strings.Mind_LaTeX_Quick_Title_7] = "Fonction"
stringMap[strings.Mind_LaTeX_Quick_Title_8] = "Symbole de relation"
stringMap[strings.Mind_LaTeX_Quick_Title_9] = "symbole de flèche"
stringMap[strings.Mind_LaTeX_Quick_Title_10] = "Caractères divers"
stringMap[strings.Mind_LaTeX_Quick_Title_11] = "accent grave"
stringMap[strings.Mind_LaTeX_Error] = "erreur de formule de latex, veuillez vous assurer que la formule est correcte"

stringMap[strings.Mind_Resource_See] = "Afficher la pièce jointe"
stringMap[strings.Mind_Resource_Delete] = "Supprimer les pièces jointes"
stringMap[strings.Mind_Edit_Drag_And_Drop_The_explain_Here] = "Faites glisser et déposez les fichiers ici"
stringMap[strings.Mind_Edit_choose_explain] = "Choisir un fichier"
stringMap[strings.Message_Tips_Share_Explain_Does_Not_Exist] = "Le fichier téléchargé ne peut pas dépasser 20 Mo"

stringMap[strings.Please_Enter_Mind_Password] = "Veuillez saisir un mot de passe de 4 à 8 chiffres"
stringMap[strings.Please_Enter_Mind_Password_For_Unlock] = "Veuillez saisir le mot de passe"
stringMap[strings.Please_Enter_Mind_Password_Member_Tips] = "Cette fonctionnalité est une fonctionnalité membre"
stringMap[strings.Please_Enter_Mind_Password_Remove_Tips] = "Mot de passe du fichier supprimé"
stringMap[strings.Please_Enter_Mind_Password_Length_Short] = "Le nombre de bits de mot de passe n'est pas suffisant, veuillez entrer un mot de passe de 4 à 8 bits"
stringMap[strings.Please_Enter_Mind_Password_Length_Long] = "Le mot de passe est trop long."
stringMap[strings.Please_Enter_Mind_Password_Success] = "Mot de passe défini avec succès"
stringMap[strings.Please_Enter_Mind_Password_Format_Error] = "Mauvais format de mot de passe"
stringMap[strings.Mind_Load_Data_Error] = "Erreur de chargement des données"
stringMap[strings.Please_Enter_Mind_Password_Error] = "Erreur de mot de passe"
stringMap[strings.Please_Enter_Mind_Password_For_Owner] = "Seul le créateur du diagramme peut définir le mot de passe"
stringMap[strings.Mind_Style_Tight_Mode_Label] = "Tight layout"
stringMap[strings.Mind_Style_Same_Level_Alignment_Label] = "Sibling alignment"
stringMap[strings.Mind_Style_Node_Overlap] = "Theme overlap"
stringMap[strings.Mind_Style_To_Peer] = "Use to peer"
stringMap[strings.Mind_Style_To_All_Son_Subject] = "Use to all subtopics"
stringMap[strings.Mind_Edit_Node_Menu_Save_Picture] = "Save Picture As"
stringMap[strings.Mind_Edit_Order_Label] = 'Order'
stringMap[strings.Mind_Edit_Order_1_Label] = '1, 2, 3, ...'
stringMap[strings.Mind_Edit_Order_2_Label] = 'Ⅰ, Ⅱ, Ⅲ, ...'
stringMap[strings.Mind_Edit_Order_3_Label] = 'ⅰ, ⅱ, ⅲ, ...'
stringMap[strings.Mind_Edit_Order_4_Label] = 'A, B, C, ...'
stringMap[strings.Mind_Edit_Order_5_Label] = 'a, b, c, ...'
stringMap[strings.Mind_Edit_Order_6_Label] = '(1),(2),(3), ...'
stringMap[strings.Mind_Edit_Order_7_Label] = '①, ②, ③, ...'
stringMap[strings.Mind_Edit_Order_8_Label] = '一, 二, 三, ...'
stringMap[strings.Mind_Edit_Order_Level_1_Label] = '1 Level'
stringMap[strings.Mind_Edit_Order_Level_2_Label] = '2 Level'
stringMap[strings.Mind_Edit_Order_Level_3_Label] = '3 Level'
stringMap[strings.Mind_Edit_Order_Level_4_Label] = '4 Level'
stringMap[strings.Mind_Edit_Order_Remove_Label] = 'None'
stringMap[strings.Mind_Edit_Open_Hidde_Title] = 'Display Layers'
stringMap[strings.Mind_Edit_Open_Hidde_1] = '1 Layers'
stringMap[strings.Mind_Edit_Open_Hidde_2] = '2 Layers'
stringMap[strings.Mind_Edit_Open_Hidde_3] = '3 Layers'
stringMap[strings.Mind_Edit_Open_Hidde_4] = '4 Layers'
stringMap[strings.Mind_Edit_Open_Hidde_5] = '5 Layers'
stringMap[strings.Mind_Edit_Open_Hidde_All] = 'Show All Layers'

stringMap[strings.Mind_Edit_Node_Menu_Ai_Label] = "Ai Helper-GPT"
stringMap[strings.Mind_Edit_Ai_Title] = 'Ai Helper'
stringMap[strings.Mind_Ai_Empty_Tips_1_Label] = 'Enter the question you want to obtain'
stringMap[strings.Mind_Ai_Empty_Tips_2_Label] = 'Please use Ai assistant in a reasonable and civilized manner'
stringMap[strings.Mind_Ai_To_Map_Label] = 'Add MindMap'
stringMap[strings.Mind_Copy_Cuccess_Label] = 'Successfully copied'
stringMap[strings.Mind_Ai_Input_Hint_Label] = 'Please enter a question...'
stringMap[strings.Mind_Ai_Submit_Label] = 'Submit'
stringMap[strings.Mind_Ai_Exceeding_Limit_Label] = 'Sorry, it has been used too many times a day. Please use it again tomorrow'
stringMap[strings.Mind_Ai_To_Senior_Member_Label] = 'Today\'s usage has been exhausted, permanent members can receive more daily usage times'
stringMap[strings.Mind_Ai_To_Member_Label] = 'Free usage has been used up, members can receive more daily usage times'
stringMap[strings.Mind_Ai_Wait_0_Label] = 'AI Loading'
stringMap[strings.Mind_Ai_Wait_1_Label] = 'Ai is collecting information'
stringMap[strings.Mind_Ai_Wait_2_Label] = 'Ai is organizing optimization information'
stringMap[strings.Mind_Ai_Wait_3_Label] = 'Please be patient. Your question is quite professional'
stringMap[strings.Mind_Ai_Wait_4_Label] = 'Please be patient and wait'
stringMap[strings.Mind_Ai_Wait_5_Label] = 'Already on the way back'
stringMap[strings.Mind_Ai_Submit_Content_Psuffix_Label] = 'Please list the steps'
stringMap[strings.Mind_Ai_Service_Upgraded_Label] = 'The Ai service is being upgraded, please try again later'
stringMap[strings.Mind_Node_To_Card_Label] = "To Card"//关联导图||association map        
stringMap[strings.My_Mind_Left_Right_Focus_Modol] = "Divergent thinking model"
stringMap[strings.My_Mind_Left_Right_Free_Modol] = "Free streamline model"
stringMap[strings.My_Mind_Left_Right_Neat_Modol] = "Notes - Organize Model"
stringMap[strings.My_Mind_Left_Right_Fresh_Modol] = "Full Line - Fresh Model"
stringMap[strings.My_Mind_Tree_Left_Right_Modol] = "Hierarchical Relational model"
stringMap[strings.My_Mind_Left_Right_Outline_Modol] = "Quick and on the go"
stringMap[strings.My_Mind_Fish_Modol] = "Causal analysis model"
stringMap[strings.My_Mind_Bridge_Modol] = "Analogical similarity model"
stringMap[strings.My_Mind_Brackets_Right_Modol] = "Total Score Relational model"
stringMap[strings.My_Mind_Radiate_Modol] = "Relationship aggregation model"
stringMap[strings.My_Mind_Right_Modol] = "Right Extended Analysis Model"
stringMap[strings.My_Mind_Left_Modol] = "Left Extended Analysis Model"
stringMap[strings.My_Mind_Form_Modol] = "Compare and block models"
stringMap[strings.My_Mind_Horizontal_Right_Modol] = "Logical relationship before and after"
stringMap[strings.My_Mind_Tree_Right_Modol] = "Vertical, sequential, and global"
stringMap[strings.My_Mind_Bottom_Modol] = "Organizational structure, parallel knowledge"
stringMap[strings.My_Mind_Top_Modol] = "Object Analysis Model"
stringMap[strings.My_Mind_Bubble_Modol] = "Attribute and relationship of things"
stringMap[strings.My_Mind_Circular_Modol] = "Classification and induction"
stringMap[strings.My_Mind_Double_Bubble_Modol] = "Commonalities and characteristics"
stringMap[strings.My_Mind_Time_Vertical_Modol] = "Vertical sequence of events"
stringMap[strings.My_Mind_Time_Horizontal_Modol] = "Horizontal order"
stringMap[strings.My_Mind_Time_S_Horizontal_Modol] = "Large amount of information, events"
stringMap[strings.My_Mind_Left_Right_Card_Modol] = "card model"
stringMap[strings.Mind_Menu_Edit_Title] = "Edit Title"
stringMap[strings.Global_Text_Find] = "Find"
stringMap[strings.Global_Text_Replace] = "Replace"
stringMap[strings.Global_Text_Replace_All] = "Replace All"
stringMap[strings.Global_Text_Find_Empty] = "No Content"
stringMap[strings.Global_Text_Find_Result] = "Item & of @"
stringMap[strings.Mind_Fast_Style_Rainbow_Label] = "Life"
stringMap[strings.Mind_Fast_Style_Solid_Label] = "Pure"
stringMap[strings.Mind_Import_File] = "Import File"

stringMap[strings.Mind_Add_Font_Label] = "+ Add local font:ttf、woff..."
stringMap[strings.Mind_Import_Font_Web_Tips] = "Please download the computer client from the official website to use this feature，https://mindyushu.com"
stringMap[strings.Mind_Import_Font_Upgrade_Label] = "The free trial version can only add one external font. Upgrading to Pro users can add more external fonts and all professional version features."

stringMap[strings.Mind_Flow_Chart_Map] = "Flow Chart"
stringMap[strings.Mind_Shape_Corner_Text] = "Process"
stringMap[strings.Mind_Shape_Start_Text] = "Start"
stringMap[strings.Mind_Shape_End_Text] = "End"
stringMap[strings.Mind_Shape_Determine_Text] = "Judging"
stringMap[strings.Mind_Shape_Ellipse_3D_Text] = "Database"
stringMap[strings.Mind_Shape_Cube_Text] = "Science"
stringMap[strings.Mind_Shape_Form_Text] = "Document"
stringMap[strings.Mind_Shape_Corner_Right_Text] = "Card"
stringMap[strings.Mind_Shape_Book_Text] = "Book"
stringMap[strings.Mind_Shape_Text] = "Text"

stringMap[strings.Mind_Map_Picture_Paddinng_H_Text] = "Horizontal"
stringMap[strings.Mind_Map_Picture_Paddinng_V_Text] = "Vertical"

stringMap[strings.Mind_Map_Create_Map_Info_Text] = "Choose multiple thinking frameworks"
stringMap[strings.Mind_Map_Create_File_Info_Text] = "Quickly manage your mind map"
stringMap[strings.Mind_Map_Open_File_Info_Text] = "Open files on your device"
module.exports = stringMap;
