const { default: strings } = require("./strings");

var stringMap = {};
stringMap[strings.About_App_Name] = 'Mind+思維導圖';
stringMap[strings.TEMPLATE] = '模板';
stringMap[strings.Mind_Edit_Main_Topic] = '分支主題';
stringMap[strings.Global_Add_Free_Node_Tips] = '點擊屏幕選擇添加位置';
stringMap[strings.Global_Add_Free_Chart_Node_Tips] = '雙擊屏幕選擇添加位置';
stringMap[strings.Global_Add_Relationship_Tips] = '選擇需要關聯的目標';
stringMap[strings.Global_Add_Target_Tips] = '請選擇一個節點添加對象';
stringMap[strings.Mind_Mindmap_ConnectMap_Exist_Tips] = '該對像已經關聯其他導圖';
stringMap[strings.Mind_Edit_Main_Idea] = '中心主題';
stringMap[strings.Mind_Mindmap_Open_ConnectMap_No_Exist_Tips] = '關聯導圖不存在';
stringMap[strings.Mind_Style_Add_Icon_Priority] = '優先級';
stringMap[strings.Mind_Style_Add_Icon_Progress] = '進度';
stringMap[strings.Mind_Style_Add_Icon_Stars] = '星星';
stringMap[strings.Mind_Style_Add_Icon_Week] = '星期';
stringMap[strings.Mind_Style_Add_Icon_Head_Portrait] = '頭像';
stringMap[strings.Establish] = '創建';
stringMap[strings.Create_From_Template] = '從模板創建';
stringMap[strings.Common] = '通用';
stringMap[strings.Function_List] = '功能列表';
stringMap[strings.Recent_Updates] = '最近更新';
stringMap[strings.The_Latest_To_Delete] = '最近刪除';
stringMap[strings.Setting_Up] = '聯繫我們';
stringMap[strings.Usinghelp] = '使用幫助';
stringMap[strings.The_Download_Client] = '下載客戶端/手機版';
stringMap[strings.Global_Purchase] = '購買';
stringMap[strings.Global_Login] = '登錄';
stringMap[strings.My_map] = '我的導圖';
stringMap[strings.Create_Map] = '創建導圖';
stringMap[strings.Storm] = '風暴';
stringMap[strings.Mind_Double_Bubble_Edit_Main_Common_Characteristic] = '共同\n特點';
stringMap[strings.Mind_Double_Bubble_Edit_Main_Characteristic] = '特點';
stringMap[strings.Mind_Organization_Edit_Main_Idea] = '組織中樞';
stringMap[strings.Mind_Edit_Free_Topic] = '自由主題';
stringMap[strings.Mind_Edit_Sub_Topic] = '子主題';
stringMap[strings.Mind_Organization_Main_Topic] = '部門';
stringMap[strings.Mind_Edit_Main_Topic] = '分支主題';
stringMap[strings.Mind_Time_Edit_Main_Idea] = '計劃安排';
stringMap[strings.Mind_Time_Edit_Head] = '時間';
stringMap[strings.Mind_Time_Edit_Title] = '計劃';
stringMap[strings.Mind_Edit_Details] = '詳細描述';
stringMap[strings.Checking_Data] = '個人資訊';
stringMap[strings.Setting_Information] = '設置信息';
stringMap[strings.Sign_Out] = '退出登錄';

//功能列表
stringMap[strings.Create_a_Mind_Map] = '創建思維導圖';
stringMap[strings.Create_Mind_Map_1] = '【1】創建十四種佈局的導圖（雙向/右側/左側/組織架構/單氣泡圖/雙氣泡圖/左側樹形圖/右側樹形圖/雙向樹形圖/表格/橫向時間線/豎向時間線/S時間線/金字塔）';
stringMap[strings.Create_Mind_Map_2] = '【2】可使用模板快速創建導圖（大量模板內容引用）';
stringMap[strings.Create_Mind_Map_3] = '【3】對導圖進行九種類型的線條樣式編輯';
stringMap[strings.Create_Mind_Map_4] = '【4】對導圖進行九種佈局的變更編輯';
stringMap[strings.Create_Mind_Map_5] = '【5】對目標節點添加子節點，添加同級節點（上、下）';
stringMap[strings.Create_Mind_Map_6] = '【6】摘要：對單個節點/多節點添加[摘要]進行說明';
stringMap[strings.Create_Mind_Map_7] = '【7】成塊：對導圖單個節點/多個節點框添加[成塊]，進行模塊化';
stringMap[strings.Create_Mind_Map_8] = '【8】添加自由節點';
stringMap[strings.Create_Mind_Map_9] = '【9】創建任意節點之間四種線條樣式的關聯';
stringMap[strings.Create_Mind_Map_10] = '【10】給節點插入圖片（本地+素材庫）、圖標、備註、鏈接';
stringMap[strings.Create_Mind_Map_11] = '【11】複製/粘貼節點，拷貝/粘貼樣式';
stringMap[strings.Create_Mind_Map_12] = '【12】撤銷，恢復撤銷';
stringMap[strings.Create_Mind_Map_13] = '【13】可多選，多個節點同步操作';
stringMap[strings.Create_Mind_Map_14] = '【14】可插入統計圖';
stringMap[strings.Create_Mind_Map_15] = '【15】整個導圖可以錄音';
stringMap[strings.Create_Mind_Map_16] = '【16】複製導圖';
stringMap[strings.Create_Mind_Map_17] = '【17】可以復制導圖或者整個導圖到其他文件的導圖';
stringMap[strings.Create_Mind_Map_18] = '【18】摘要後面可以建立導圖';
stringMap[strings.Create_Mind_Map_19] = '【19】編輯時可以切換導圖編輯（電腦端）';
stringMap[strings.Create_Mind_Map_20] = '【20】藍牙鍵盤編輯導圖：\n\t[Command]+[a]選擇主節點\n\t[Command]+[←][↑][→][↓ ]更改選擇目標或者移動導圖\n\t[Command]+[e]編輯目標\n\t[Command]+[w]創建子節點\n\t[Command]+[s]創建目標兄弟節點（上）\n\t[Command]+[d]創建目標兄弟節點（下）\n\t[Command]+[q]退出選擇的目標\n\t[Command]+[-][+]縮放導圖';

stringMap[strings.Beautify_The_Mind_Map] = '美化思維導圖';
stringMap[strings.Beautify_The_Mind_Map_1] = '【1】設置思維導圖背景色';
stringMap[strings.Beautify_The_Mind_Map_2] = '【2】設置整個導圖線條粗細，顏色，是否漸細';
stringMap[strings.Beautify_The_Mind_Map_3] = '【3】設置文本填充色、邊框顏色、邊框13種形狀、邊框寬度、陰影效果';
stringMap[strings.Beautify_The_Mind_Map_4] = '【4】設置導圖所有或部分線條顏色、寬度';
stringMap[strings.Beautify_The_Mind_Map_5] = '【5】設置文本字體大小、加粗、斜體、刪除樣式';
stringMap[strings.Beautify_The_Mind_Map_6] = '【6】設置圖片大小、位置、形狀樣式';
stringMap[strings.Beautify_The_Mind_Map_7] = '【7】添加七種類別的圖標（常用/3D/學習/工作/人物/動物/食物）並設置大小/位置';
stringMap[strings.Beautify_The_Mind_Map_8] = '【8】添加對整個導圖一鍵風格樣式引用';
stringMap[strings.Beautify_The_Mind_Map_9] = '【9】分組管理導圖';
stringMap[strings.Beautify_The_Mind_Map_10] = '【10】對整個導圖可以添加個性背景（素材庫）';
stringMap[strings.Beautify_The_Mind_Map_9] = '【11】對單個節點可以添加個性邊框或者背景（素材庫）';
stringMap[strings.Beautify_The_Mind_Map_9] = '【12】對於節點可以拖動長度';


stringMap[strings.Map_Output] = '導圖輸出';
stringMap[strings.Map_Output_1] = '【1】保存圖片';
stringMap[strings.Map_Output_2] = '【2】保存PDF';
stringMap[strings.Map_Output_3] = '【3】分享鏈接';
stringMap[strings.Map_Output_4] = '【4】分享到風暴';
stringMap[strings.Map_Output_4] = '【5】打印';

stringMap[strings.Work_together] = '協同辦公';
stringMap[strings.Work_together_1] = '【1】創建共享組（組標籤，組公告）';
stringMap[strings.Work_together_2] = '【2】新增共享組成員';
stringMap[strings.Work_together_3] = '【3】組內多成員協同編輯同一導圖';
stringMap[strings.Work_together_4] = '【4】組內上傳共享文件（word/excle/pdf）';
stringMap[strings.Work_together_5] = '【5】組內可以留言';
stringMap[strings.Work_together_6] = '【6】共享組管理權限設置';
stringMap[strings.Work_together_7] = '【7】解散組';

stringMap[strings.Work_together] = '知識共享（風暴）';
stringMap[strings.Community_Sharing_1] = '【1】可以將自己的創作分享到風暴，讓更多人看到';
stringMap[strings.Community_Sharing_1] = '【2】可以選擇標籤查看相關風暴內容';
stringMap[strings.Community_Sharing_1] = '【3】可以對於風暴作品點贊和評價';

//最近更新
stringMap[strings.Recently_Updated_Title_1] = '建立個人賬號';
stringMap[strings.Recently_Updated_Title_2] = '海量模板知識庫';
stringMap[strings.Recently_Updated_Title_3] = '支持多平台';
stringMap[strings.Recently_Updated_Title_4] = '定制每一處細節';
stringMap[strings.Recently_Updated_Title_5] = '個性風格樣式庫';
stringMap[strings.Recently_Updated_Title_6] = '導圖輸出';
stringMap[strings.Recently_Updated_Title_7] = '雲存儲';
stringMap[strings.Recently_Updated_Title_8] = '十五種類型導圖';


stringMap[strings.Recently_Updated_Content_1] = '創建個人賬號，所製作導圖跟隨賬號保存';
stringMap[strings.Recently_Updated_Content_2] = '海量模板知識庫直接引用，提高學習工作效率';
stringMap[strings.Recently_Updated_Content_3] = '支持網頁版在線製作、IOS手機端、Mac端、安卓手機端、安卓平板多平台使用';
stringMap[strings.Recently_Updated_Content_4] = '可根據個人喜好美化文本、背景、線條、圖片圖標等設計';
stringMap[strings.Recently_Updated_Content_5] = '個性風格樣式庫直接引用，省去配色煩惱';
stringMap[strings.Recently_Updated_Content_6] = '可保存圖片、PDF格式，可直接分享好友';
stringMap[strings.Recently_Updated_Content_7] = '強力雲端存儲支持，再也不用擔心資料丟失';
stringMap[strings.Recently_Updated_Content_8] = '雙向、右側、左側、組織架構、單氣泡圖、雙氣泡圖、左側樹形圖、右側樹形圖、雙向樹形圖、表格、橫向時間線、豎向時間線、S時間線，八種佈局隨意變更';

//最近刪除
stringMap[strings.Mind_Mindmap_Deleted_Title] = '最近刪除';
stringMap[strings.Mind_Mindmap_Deleted_Recovery] = '恢復';
stringMap[strings.Mind_Mindmap_Deleted_Forever] = '刪除';
stringMap[strings.Mind_Mindmap_Deleted_Filename] = '文件名';
stringMap[strings.Mind_Mindmap_Deleted_Tips] = '在此處刪除之後，將永久移除';
stringMap[strings.Mind_Mindmap_Deleted_Operating] = '操作';
stringMap[strings.Mind_Mindmap_Deleted_Selected] = '搜索最近刪除導圖';
stringMap[strings.Mind_Mindmap_Deleted_Forever_Time] = '刪除日期';

//聯繫我們
stringMap[strings.Contact_Us_No_Public] = '公眾號';
stringMap[strings.Contact_Us_QQ_Group] = 'QQ群';
stringMap[strings.Contact_Us_Message_Board] = '留言板';
stringMap[strings.Contact_Us_Username] = '姓名';
stringMap[strings.Contact_Us_Email] = '郵箱';
stringMap[strings.Contact_Us_Voicemail] = '請留下寶貴的意見';
stringMap[strings.Contact_Us_Send_Email] = '發送郵箱';

//驗證信息
stringMap[strings.Input_Username_isNull] = '姓名不能為空';
stringMap[strings.Input_Email_isNull] = '請輸入正確的郵箱';
stringMap[strings.Input_Voicemail_isNull] = '請輸入發送內容';
stringMap[strings.Input_Introduction_isNull] = '簡介不能為空';
stringMap[strings.Input_Title_isNull] = '標題不能為空';

stringMap[strings.Input_Please_Enter_A_Valid_Phone_Number] = '請輸入正確的手機號';
stringMap[strings.Input_Please_Enter_Verification_Code] = '請輸入驗證碼！ ';
stringMap[strings.Input_Please_Enter_The_Password] = '請輸入密碼！ ';
stringMap[strings.Input_The_Password_Format_Is_Incorrect] = '密碼格式不正確！ ';
stringMap[strings.Input_Please_Enter_A_New_Password] = '請輸入新密碼！ ';
stringMap[strings.Input_Confirm_The_New_Password] = '請輸入確認密碼！ ';
stringMap[strings.Input_The_Passwords_Are_Inconsistent] = '密碼不一致！ ';
stringMap[strings.Input_The_Please_Enter_The_Correct_Verification_Code] = '請輸入正確驗證碼！ ';
stringMap[strings.Input_The_Verification_Code_Error] = '驗證碼錯誤！ ';

//使用幫助
stringMap[strings.Mind_Usinghelp_1] = '創建思維導圖';
stringMap[strings.Mind_Usinghelp_2] = '如何新增節點';
stringMap[strings.Mind_Usinghelp_3] = '創建自由節點';
stringMap[strings.Mind_Usinghelp_4] = '建立關聯';
stringMap[strings.Mind_Usinghelp_5] = '美化畫布';
stringMap[strings.Mind_Usinghelp_6] = '美化文本';
stringMap[strings.Mind_Usinghelp_7] = '插入備註、鏈接';
stringMap[strings.Mind_Usinghelp_8] = '插入圖標';
stringMap[strings.Mind_Usinghelp_9] = '插入圖片';
stringMap[strings.Mind_Usinghelp_10] = '如何恢復刪除';
stringMap[strings.Mind_Usinghelp_11] = '導出思維導圖';
stringMap[strings.Mind_Usinghelp_12] = '會員升級';
stringMap[strings.Mind_Usinghelp_13] = '快捷鍵';
stringMap[strings.Mind_Usinghelp_Understood] = '知道了';
stringMap[strings.Mind_Usinghelp_14] = '插入素材庫';
//使用幫助-2
stringMap[strings.Mind_Usinghelp_15] = '新建導圖';
// stringMap[strings.Mind_Usinghelp_2] = '添加節點';
// stringMap[strings.Mind_Usinghelp_4] = '建立關聯';
// stringMap[strings.Mind_Usinghelp_5] = '美化畫布';
// stringMap[strings.Mind_Usinghelp_6] = '美化文本';
// stringMap[strings.Mind_Usinghelp_7] = '添加備註、鏈接';
stringMap[strings.Mind_Usinghelp_17] = '撤銷操作、取消';
stringMap[strings.Mind_Usinghelp_18] = '拖動節點換位';
stringMap[strings.Mind_Usinghelp_19] = '節點對齊';
stringMap[strings.Mind_Usinghelp_20] = '變更導圖類型';
stringMap[strings.Mind_Usinghelp_21] = '導圖線條樣式';
stringMap[strings.Mind_Usinghelp_22] = '導圖背景顏色';
stringMap[strings.Mind_Usinghelp_23] = '設置節點形狀';
stringMap[strings.Mind_Usinghelp_24] = '節點線條樣式';
stringMap[strings.Mind_Usinghelp_25] = '節點圖片設置';
stringMap[strings.Mind_Usinghelp_26] ='節點圖標設置';
stringMap[strings.Mind_Usinghelp_27] = '節點添加摘要';
stringMap[strings.Mind_Usinghelp_28] = '單節點框選';
stringMap[strings.Mind_Usinghelp_29] = '多節點成塊';
stringMap[strings.Mind_Usinghelp_30] = '節點添加邊框';
stringMap[strings.Mind_Usinghelp_31] = '添加統計圖';
stringMap[strings.Mind_Usinghelp_32] = '隱藏節點及恢復';
stringMap[strings.Mind_Usinghelp_33] = '節點多個操作';
stringMap[strings.Mind_Usinghelp_34] = '更換風格';
stringMap[strings.Mind_Usinghelp_35] = '插入語音及解釋';
stringMap[strings.Mind_Usinghelp_36] = '導圖導成圖片';
stringMap[strings.Mind_Usinghelp_37] = '導圖PDF文檔';
stringMap[strings.Mind_Usinghelp_38] = '打印導圖';
stringMap[strings.Mind_Usinghelp_39] = '鏈接分享及查看';
stringMap[strings.Mind_Usinghelp_40] = '導圖分享到風暴';
stringMap[strings.Mind_Usinghelp_41] = '文件夾的使用';
stringMap[strings.Mind_Usinghelp_42] = '刪除及恢復導圖';
//使用幫助3
stringMap[strings.Mind_Usinghelp_43] = '好友申請加入組';
stringMap[strings.Mind_Usinghelp_44] = '如何解散組';
stringMap[strings.Mind_Usinghelp_45] = '上傳文件資料';
stringMap[strings.Mind_Usinghelp_46] = '同意加入申請';
stringMap[strings.Mind_Usinghelp_47] = '協同編輯';
stringMap[strings.Mind_Usinghelp_48] = '新建共享組';
stringMap[strings.Mind_Usinghelp_49] = '邀請組成員';

//風暴
stringMap[strings.Mind_Storm_Recommend] = '推薦';
stringMap[strings.Mind_Storm_Boilingpoint] = '沸點';
stringMap[strings.Mind_Storm_Unfold] = '展開';
stringMap[strings.Mind_Storm_Putaway] = '收起';
stringMap[strings.Mind_Storm_Myclassification] = '我的分類';
stringMap[strings.Mind_Storm_Recommended_Classification] = '推薦分類';
stringMap[strings.Mind_Storm_Click_Add_Category] = '點擊添加分類';
stringMap[strings.Mind_Storm_No_Classification] = '暫無分類';
stringMap[strings.Mind_Storm_No_Tinterested] = '不感興趣';
stringMap[strings.Mind_Storm_Report] = '舉報';
stringMap[strings.Mind_Storm_Views] = '瀏覽次數';

stringMap[strings.Mind_Storm_Pornographic] = '色情低俗';
stringMap[strings.Mind_Storm_Advertising_Harassment] = '廣告騷擾';
stringMap[strings.Mind_Storm_Political_Religion] = '政治宗教';
stringMap[strings.Mind_Storm_Infringement] = '侵權（肖像、誹謗、抄襲、冒用）';
stringMap[strings.Mind_Storm_Prohibited_Content] = '違禁內容';
stringMap[strings.Mind_Storm_Spread_Rumors] = '造謠傳謠';
stringMap[strings.Mind_Storm_Report_Successful] = '舉報成功';

//編輯
stringMap[strings.Global_Edit] = '編輯';
stringMap[strings.Global_Cancel] = '取消';
stringMap[strings.Global_Ok] = '確定';
stringMap[strings.Global_Name] = '暱稱';
stringMap[strings.Global_Introduction] = '簡介';
stringMap[strings.Global_Change_Avatar] = '更換頭像';
stringMap[strings.Global_Local_Upload] = '本地上傳';

//個人資料
stringMap[strings.User_Personal_information] = '個人資料';
stringMap[strings.User_Member_Validity_Period] = '會員有效期';
stringMap[strings.User_My_Share] = '我的分享';
stringMap[strings.User_My_Collection] = '我的收藏';
stringMap[strings.User_More_Message] = '更多';
stringMap[strings.User_Deleted_Sotrm] = '刪除風暴';
stringMap[strings.User_Folder_Rename] = '重命名';
stringMap[strings.User_Folder_Rename_Root] = '修改權限';
stringMap[strings.User_Folder_Rename_Root_Title] = '修改權限';
stringMap[strings.User_Folder_Root_Public] = '完全公開';
stringMap[strings.User_Folder_Root_Viewonly] = '文件夾公開僅允許查看';
stringMap[strings.User_Folder_Root_Private] = '私密';
stringMap[strings.User_Folder_Root_Public_Introduction] = '在您的個人資料頁其他人可以查看您的收藏夾';
stringMap[strings.User_Message_Link] = '鏈接';
stringMap[strings.User_New_Group] = '新建分組';
stringMap[strings.User_New_Group_Title] = '創建新的分組';
stringMap[strings.User_New_Group_Style_Title] = '文件類型';
stringMap[strings.User_Share_Is_Null] = '暫無分享！ ';
stringMap[strings.User_Deleted_Storm_Tips] = '確定刪除該風暴嗎？ ';
stringMap[strings.User_Remove_From_List] = '從列表刪除',
    stringMap[strings.User_Move_To] = '移動到',
    stringMap[strings.User_Deleted_Link] = '刪除鏈接'
stringMap[strings.User_Collction_Is_Null] = '暫無收藏'
stringMap[strings.User_Collection] = '收藏'

//登錄框
stringMap[strings.User_Lolgin_Welcome_To_Login] = '歡迎登陸Mind+'
stringMap[strings.User_Lolgin_Free_Registration] = '免費註冊'
stringMap[strings.User_Lolgin_Please_Enter_Phone_Number] = '請輸入手機號'
stringMap[strings.User_Lolgin_Please_Enter_Verification_Code] = '請輸入驗證碼'
stringMap[strings.User_Lolgin_Obtain] = '獲取'
stringMap[strings.User_Lolgin_Password_Login] = '密碼登錄'
stringMap[strings.User_Lolgin_Login] = '登錄'
stringMap[strings.User_Lolgin_Register_An_Account] = '賬號註冊'
stringMap[strings.User_Lolgin_Already_Have_An_Account] = '已有賬號'
stringMap[strings.User_Lolgin_To_Log_In] = '去登陸'
stringMap[strings.User_Lolgin_Please_Enter_Password] = '請輸入密碼'
stringMap[strings.User_Lolgin_Confirm_Password] = '確認密碼'
stringMap[strings.User_Lolgin_Registered] = '註冊'
stringMap[strings.User_Lolgin_Account_Login] = '賬號登錄'
stringMap[strings.User_Lolgin_Scan_Code_Login] = '掃碼登錄'
stringMap[strings.User_Lolgin_Forgot_Password] = '忘記密碼'
stringMap[strings.User_Lolgin_Verification_Code_Login] = '驗證碼登錄'
stringMap[strings.User_Lolgin_Open_Mind_And_Scan_At_The_Top_Of_The_Setting_Interface] = '打開Mind+在設置界面頂部掃一掃'
stringMap[strings.User_Lolgin_This_QR_Code_Expires_In_Five_Minutes_Please_Click] = '此二維碼五分鐘過期，過期請點擊'
stringMap[strings.User_Lolgin_Refresh] = '刷新'
stringMap[strings.User_Lolgin_Scanning_Is_Successful_Please_Confirm_On_Your_Phone] = '掃描成功，請在手機上確認'
stringMap[strings.User_Lolgin_Whether_Yo_Authorize_Login] = '是否授權登錄'
stringMap[strings.User_Lolgin_Please_Enter_New_Password] = '請輸入新密碼'
stringMap[strings.User_Lolgin_Reset] = '重置'

//我的導圖
stringMap[strings.My_Mind_Search_Template_Type_Or_Template] = '搜索模板類型或模板'
stringMap[strings.My_Mind_Date] = '日期'
stringMap[strings.My_Mind_Create_Folder] = '創建文件夾'
stringMap[strings.My_Mind_Please_Enter_The_Folder_Name] = '請輸入文件夾名'
stringMap[strings.My_Mind_Please_Enter_A_New_Folder_Name] = '請輸入新文件夾名'
stringMap[strings.My_Mind__No_Map_Yet_Go] = '暫無導圖 快去'
stringMap[strings.My_Mind_Create] = '創建'
stringMap[strings.My_Mind_Right] = '吧!'
stringMap[strings.My_Mind_Open] = '打開'
stringMap[strings.My_Mind_Type_Left_Right] = '雙嚮導圖'
stringMap[strings.My_Mind_Type_Unilateral] = '邏輯圖'
stringMap[strings.My_Mind_Type_Bottom] = '組織架構圖'
stringMap[strings.My_Mind_Type_Bubble] = '氣泡圖'
stringMap[strings.My_Mind_Type_Time_Vertical] = '日程安排圖'
stringMap[strings.My_Mind_Blank_Mind_Map] = '空白思維導圖'
stringMap[strings.My_Mind_All] = '全部'
stringMap[strings.My_Mind_Views] = '瀏覽次數：'

//風暴搜索
stringMap[strings.Storm_Search_How_Title] = '熱門標籤'
stringMap[strings.Storm_History_Search] = '歷史搜索'

//公共 header
stringMap[strings.Public_Header_Mind] = '導圖'
stringMap[strings.Public_Header_Outline] = '大綱'
stringMap[strings.Public_Header_Download_Apps] = '下載客戶端'
stringMap[strings.Public_Header_Mobile_Version] = '手機版'
stringMap[strings.Public_Header_Creator] = '創作者：'
stringMap[strings.Public_Header_View_Frequency] = '查看次數：'
stringMap[strings.Public_Header_Save] = '保存'
stringMap[strings.Header_Mind_Tips_Saved] = '已保存'
stringMap[strings.Header_Mind_Tips_Edit] = '已編輯'
stringMap[strings.Header_Mind_Tips_Update_Saved] = '更新已保存'

//導圖編輯菜單
stringMap[strings.Mind_Edit_Revoke] = '撤銷'
stringMap[strings.Mind_Edit_Restore] = '恢復'
stringMap[strings.Mind_Edit_Subtopic] = '子主題'
stringMap[strings.Mind_Edit_Top_Subtopic] = '上子主題'
stringMap[strings.Mind_Edit_Bottom_Subtopic] = '下子主題'
stringMap[strings.Mind_Edit_Free_Node] = '自由節點'
stringMap[strings.Mind_Edit_Summary] = '摘要'
stringMap[strings.Mind_Edit_Block] = '成塊'
stringMap[strings.Mind_Edit_Insert] = '插入'
stringMap[strings.Mind_Edit_Related] = '關聯'
stringMap[strings.Mind_Edit_Style] = '風格'
stringMap[strings.Mind_Edit_Recording] = '錄音'
stringMap[strings.Mind_Edit_Insert_Picture] = '插入圖片'
stringMap[strings.Mind_Edit_Insert_Icon] = '插入圖標'
stringMap[strings.Mind_Edit_Insert_Remarks] = '插入備註'
stringMap[strings.Mind_Edit_Insert_Link] = '插入鏈接'
stringMap[strings.Mind_Edit_Insert_Summary_Graph] = '插入統計圖'
stringMap[strings.Mind_Edit_Upload] = '上傳'
stringMap[strings.Mind_Edit_Right_Icon] = '圖標'
stringMap[strings.Mind_Edit_Right_Style] = '樣式'
stringMap[strings.Mind_Edit_Audio_Decoding] = '解碼中...'
stringMap[strings.Mind_Edit_Audio_Duration] = '時長'
stringMap[strings.Mind_Edit_Delete_Audio_Tips] = '您確定刪除錄製的音頻嗎？ '
stringMap[strings.Mind_Edit_Join_Membership] = '開通會員'
stringMap[strings.Mind_Edit_Upgrade] = '升級'
stringMap[strings.Mind_Edit_Upgrade_Item] = "陞級到Pro"
stringMap[strings.Mind_Edit_Join_Membership_Tips] = '會員可以有更多的錄製時長，現在去升級成會員？ '

stringMap[strings.Mind_Edit_Choose_Material] = '選擇素材'
stringMap[strings.Mind_Edit_Drag_And_Drop_The_Picture_Here] = '拖放圖片至此'
stringMap[strings.Mind_Edit_Select_locally] = '從本地選擇'
stringMap[strings.Mind_Edit_Choose_From] = '從素材庫選擇'
stringMap[strings.Mind_Edit_Mine] = '我的'
stringMap[strings.Mind_Edit_My_Material] = '我的素材'
stringMap[strings.Mind_Edit_Recently_Used] = '最近使用'
stringMap[strings.Mind_Edit_Use] = '使用'
stringMap[strings.Mind_Edit_No_Material_Temporarily_Go_Add_It] = '暫無素材 快去添加吧！ '
stringMap[strings.Mind_Edit_Select_Chart_Type] = '選擇統計圖類型'
stringMap[strings.Mind_Edit_Remarks] = '備註'
stringMap[strings.Mind_Edit_Remarks_Tips] = '請輸入備註內容'
stringMap[strings.Mind_Edit_Add] = '添加'
stringMap[strings.Mind_Edit_Please_Enter_The_Link] = '請輸入鏈接'

//編輯頁左側菜單
stringMap[strings.Mind_Edit_Left_Menu_Save_Picture] = '保存圖片'
stringMap[strings.Mind_Edit_Left_Menu_Export_Pdf] = '導出PDF'
stringMap[strings.Mind_Edit_Left_Menu_Share_Links] = '分享鏈接'
stringMap[strings.Mind_Edit_Left_Menu_Share_The_Storm] = '分享到風暴'
stringMap[strings.Mind_Edit_Left_Menu_Printing] = '打印'
stringMap[strings.Mind_Edit_Left_Menu_Unlock_Full_Version] = '解鎖完整版本'
stringMap[strings.Mind_Edit_Left_Menu_Help] = '幫助'
stringMap[strings.Mind_Edit_Left_Menu_Save_Picture_Format] = '保存格式'
stringMap[strings.Mind_Edit_Left_Menu_Save_Picture_Png] = 'png格式'
stringMap[strings.Mind_Edit_Left_Menu_Save_Picture_Jpg] = 'jpg格式'
stringMap[strings.Mind_Edit_Left_Menu_Save_Picture_Webp] = 'webp格式'
stringMap[strings.Mind_Edit_Left_Menu_Share_Map] = '分享導圖'
stringMap[strings.Mind_Edit_Left_Menu_Share_Switch] = '分享開關'
stringMap[strings.Mind_Edit_Left_Menu_The_Link_Can_Be_Copied_And_Sent] = '可將鏈接複製並發送'
stringMap[strings.Mind_Edit_Left_Menu_Copy_Link] = '複製鏈接'
stringMap[strings.Mind_Edit_Left_Menu_Follow_The_Link] = '如果你想更換鏈接，請點擊'
stringMap[strings.Mind_Edit_Left_Menu_Regenerate] = '重新生成'
stringMap[strings.Mind_Edit_Left_Menu_Link_Password] = '鏈接密碼：'
stringMap[strings.Mind_Edit_Left_Menu_Allow_Save] ='允許保存'
stringMap[strings.Mind_Edit_Left_Menu_Maximum_Number_Of_Views] = '最多可查看次數'
stringMap[strings.Mind_Edit_Left_Menu_Infinite] = '無限'
stringMap[strings.Mind_Edit_Left_Menu_Limit] = '限制'
stringMap[strings.Mind_Edit_Left_Menu_Second] = '次'
stringMap[strings.Mind_Edit_Left_Menu_Link_Validity] = '鏈接有效期'
stringMap[strings.Mind_Edit_Left_Menu_1_Day] = '1天'
stringMap[strings.Mind_Edit_Left_Menu_3_Days] = '3天'
stringMap[strings.Mind_Edit_Left_Menu_7_Days] = '7天'
stringMap[strings.Mind_Edit_Left_Menu_Permanent] = '永久'
stringMap[strings.Mind_Edit_Left_Menu_Copy_Content_And_Link] = '複製內容&鏈接 '
stringMap[strings.Mind_Edit_Left_Menu_Regenerate_Tips] = '您確定要重新生成？之前的鏈接將會失效！ '
stringMap[strings.Mind_Edit_Left_Menu_Share] = '分享'
stringMap[strings.Mind_Edit_Left_Menu_Custom_Category] = '自定義分類：'
stringMap[strings.Mind_Edit_Left_Menu_Allow_Comments] = '是否允許評論'
stringMap[strings.Mind_Edit_Left_Menu_Edit_Add_Category] = '編輯添加分類'
stringMap[strings.Mind_Edit_Left_Menu_Membership_Rights] = '會員權益'
stringMap[strings.Mind_Edit_Left_Menu_Support_Platform] = '支持平台'
stringMap[strings.Mind_Edit_Left_Menu_Open_Membership] = '開通會員'
stringMap[strings.Mind_Edit_Left_Menu_Activation_Code_Exchange] = '激活碼兌換'
stringMap[strings.Mind_Edit_Left_Menu_purchase_Tips] = '購買完即可會員權益'
stringMap[strings.Mind_Edit_Left_Menu_Payment_Type] = '選擇支付方式'
stringMap[strings.Mind_Edit_Left_Menu_Activity_Price] = '活動價'
stringMap[strings.Mind_Edit_Left_Menu_Amount_Actually_Paid] = '實付金額'
stringMap[strings.Mind_Edit_Left_Menu_Immediate_Renewal] = '立即續費'
stringMap[strings.Mind_Edit_Left_Menu_Terms_Of_Service] = '《服務條款》'
stringMap[strings.Mind_Edit_Left_Menu_Privacy_Policy] = '《隱私政策》'
stringMap[strings.Mind_Edit_Left_Menu_Activation_Code] = '激活碼：'
stringMap[strings.Mind_Edit_Left_Menu_Please_Input_Activation_Code] = '請輸入激活碼'
stringMap[strings.Mind_Edit_Left_Menu_Verify_Activation_Code] = '驗證激活碼'
stringMap[strings.Mind_Edit_Left_Menu_Select_Categories] = '選擇分類'
stringMap[strings.Mind_Edit_Left_Menu_Main_Title_Cannot_Be_Empty] = '主標題不能為空'
stringMap[strings.Mind_Edit_Left_Menu_Coupon] = '優惠券：'
stringMap[strings.Mind_Edit_Left_Menu_Please_Enter_The_Coupon_Code] = '請輸入優惠券碼'
stringMap[strings.Mind_Confirm_Use_Coupons] = '確認使用優惠券'
stringMap[strings.Mind_Edit_Left_Menu_Verification] = '驗證'
stringMap[strings.Mind_Edit_Left_Menu_Preferential_Amount] = '優惠金額'
stringMap[strings.Mind_Edit_Left_Menu_Pay_Immediately] = '立即支付'
stringMap[strings.Mind_Edit_Left_Menu_Wx_Pay] = '微信支付'
stringMap[strings.Mind_Edit_Left_Menu_Confirm_Exchange_Information] = '確認兌換信息'
stringMap[strings.Mind_Edit_Left_Menu_Exchange_Code] = '兌換碼：'
stringMap[strings.Mind_Edit_Left_Menu_Confirm_Exchange] = '兌換'

//編輯頁右側菜單
stringMap[strings.Mind_Edit_Right_Menu_canvas] = '畫布'
stringMap[strings.Mind_Edit_Right_Menu_background_Color] = '背景顏色'
stringMap[strings.Mind_Edit_Right_Menu_layout] = '佈局'
stringMap[strings.Mind_Edit_Right_Menu_Layout_branch] = "分支結構"
stringMap[strings.Mind_Edit_Right_Menu_Line_branch] = "分支線條"
stringMap[strings.Mind_Edit_Right_Menu_Line_Style] = '線條樣式'
stringMap[strings.Mind_Edit_Right_Menu_line_Color] = '線條顏色'
stringMap[strings.Mind_Edit_Right_Menu_Line_Width] = '線條寬度'
stringMap[strings.Mind_Edit_Right_Menu_Line_Tapering] = '線條漸細'
stringMap[strings.Mind_Edit_Right_Menu_Theme_Font] = '主題字體'
stringMap[strings.Mind_Edit_Right_Menu_Child_Font] = '子級字體'
stringMap[strings.Mind_Edit_Right_Menu_text_Color] = '文本顏色'
stringMap[strings.Mind_Edit_Right_Menu_font_Size] = '字體大小'
stringMap[strings.Mind_Edit_Right_Menu_Font_Style] = '字體樣式'
stringMap[strings.Mind_Edit_Right_Menu_Border_Color] = '邊框顏色'
stringMap[strings.Mind_Edit_Right_Menu_Fill_Color] = '填充色'
stringMap[strings.Mind_Edit_Right_Menu_Border_Shape] = '邊框形狀'
stringMap[strings.Mind_Edit_Right_Menu_Border_Width] = '邊框寬度'
stringMap[strings.Mind_Edit_Right_Menu_Show_Shadow_Effect] = '顯示陰影效果（訂閱功能）'
stringMap[strings.Mind_Edit_Right_Menu_Line_Color] = '線條顏色'
stringMap[strings.Mind_Edit_Right_Menu_Dotted_Line] = '虛線'
stringMap[strings.Mind_Edit_Right_Menu_Picture_Size] = '圖片大小'
stringMap[strings.Mind_Edit_Right_Menu_Picture_Location] = '圖片位置'
stringMap[strings.Mind_Edit_Right_Menu_Picture_Shape] = '圖片形狀'
stringMap[strings.Mind_Edit_Right_Menu_Icon_Size] = '圖標大小'
stringMap[strings.Mind_Edit_Right_Menu_Icon_Position] = '圖標位置'
stringMap[strings.Mind_Edit_Right_Menu_routine] = '常規'
stringMap[strings.Mind_Edit_Right_Menu_priority] = '優先級'
stringMap[strings.Mind_Edit_Right_Menu_Progress] = '進度'
stringMap[strings.Mind_Edit_Right_Menu_Stars] = '星星'
stringMap[strings.Mind_Edit_Right_Menu_Week] = '星期'
stringMap[strings.Mind_Edit_Right_Menu_Head_Portrait] = '頭像'
stringMap[strings.Mind_Edit_Right_Menu_3D] = '3D'
stringMap[strings.Mind_Edit_Right_Menu_Learning] = '學習'
stringMap[strings.Mind_Edit_Right_Menu_Work] = '工作'
stringMap[strings.Mind_Edit_Right_Menu_Characters] = '人物'
stringMap[strings.Mind_Edit_Right_Menu_Animals] = '動物'
stringMap[strings.Mind_Edit_Right_Menu_Food] = '食物'
stringMap[strings.Mind_Edit_Node_Menu_Copy_Style] = '拷貝樣式'
stringMap[strings.Mind_Edit_Node_Menu_Sticking_Style] = '黏貼樣式'
stringMap[strings.Mind_Edit_Node_Menu_Modify_Shape] = '修改形狀'
stringMap[strings.Mind_Edit_Node_Menu_View_Picture] = '查看圖片'
stringMap[strings.Mind_Edit_Node_Menu_Delete_Picture] = '刪除圖片'
stringMap[strings.Mind_Edit_Node_Menu_Copy] = '複製'
stringMap[strings.Mind_Edit_Node_Menu_Paste] = '粘貼'
stringMap[strings.Mind_Edit_Node_Menu_Delete_Line] = '刪除線條'
stringMap[strings.Mind_Edit_Node_Menu_Delete_Wrapping_Line] = '刪除'
stringMap[strings.Mind_Edit_Node_Menu_Edit_Remarks] = '編輯備註'
stringMap[strings.Mind_Edit_Node_Menu_Open_Link] = '打開鏈接'
stringMap[strings.Mind_Edit_Node_Menu_Delete_Remarks] = '刪除備註'
stringMap[strings.Mind_Edit_Node_Menu_Delete_Link] = '刪除鏈接'
stringMap[strings.Mind_Edit_Node_Menu_Delete_Icon] = '刪除圖標'
stringMap[strings.Mind_Edit_Node_Menu_Icon_Style] = '圖標樣式'

//統計圖編輯面板
stringMap[strings.Echart_Edit_Statistical] = '統計'
stringMap[strings.Echart_Edit_Value] = '值'
stringMap[strings.Echart_Edit_Color] = '顏色'
stringMap[strings.Echart_Edit_Title] = '標題'
stringMap[strings.Echart_Edit_Detailed_Description] = '詳細描述'
stringMap[strings.Echart_Edit_Value_Sort] = '排序'
stringMap[strings.Echart_Edit_Value_Display_Type] = '展示類型'
stringMap[strings.Echart_Edit_Value_Color_Scheme] = '配色方案'
stringMap[strings.Echart_Edit_Value_Display_Size] = '展示大小'
stringMap[strings.Echart_Edit_Value_Title_Size] = '標題大小'
stringMap[strings.Echart_Edit_Value_Explain_Size] = '解釋大小'
stringMap[strings.Echart_Edit_Value_Subscribe] = '訂閱'

//風暴標籤頁
stringMap[strings.StormBookTag_Subscribed] = '已訂閱'
stringMap[strings.StormBookTag_List] = '列表'

//風暴分享頁
stringMap[strings.Storm_Share_Comment] = '評論'
stringMap[strings.Storm_Share_Reply] = '回复'
stringMap[strings.Storm_Share_Let_Me_Comment_Too] = '我也來評論一下吧~'
stringMap[strings.Storm_Share_Release] = '發布'
stringMap[strings.Storm_Share_Main_Title] = '主標題'
stringMap[strings.Storm_Share_Subtitle] = '副標題'
stringMap[strings.Storm_Share_Author] = '作者'
stringMap[strings.Storm_Share_Replies] = '條回复'
stringMap[strings.Storm_Share_Comment_Is_Null_Tips] = '暫無評論，快來搶沙發吧'
stringMap[strings.Storm_Share_Comment_Input_Is_Null_Tips] = '請輸入評論呢~'
stringMap[strings.Storm_Share_Delete_Comment_Tips] = '確定刪除該評論嗎？ '

//導圖內部使用幫助
stringMap[strings.Mind_Usinghelp_Btn_1] = '快捷鍵(windows)'
stringMap[strings.Mind_Usinghelp_Btn_2] = '快捷鍵(mac)'
stringMap[strings.Mind_Usinghelp_Btn_3] = '描述'
stringMap[strings.Mind_Usinghelp_Btn_4] = '[Ctrl] + [D]'
stringMap[strings.Mind_Usinghelp_Btn_5] = '[Command] + [D]'
stringMap[strings.Mind_Usinghelp_Btn_6] = '添加子節點'
stringMap[strings.Mind_Usinghelp_Btn_7] = '[Delet]'
stringMap[strings.Mind_Usinghelp_Btn_8] = '刪除當前節點（注意，如果是編輯狀態就不可以刪除了）'
stringMap[strings.Mind_Usinghelp_Btn_9] = '[Ctrl] + [E]'
stringMap[strings.Mind_Usinghelp_Btn_10] = '[Command] + [E]'
stringMap[strings.Mind_Usinghelp_Btn_11] = '修改當前節點'
stringMap[strings.Mind_Usinghelp_Btn_12] = '[Ctrl] + [↑] [↓] [←] [→]'
stringMap[strings.Mind_Usinghelp_Btn_13] = '[Command] + [↑] [↓] [←] [→]'
stringMap[strings.Mind_Usinghelp_Btn_14] = '[Ctrl] + [+]'
stringMap[strings.Mind_Usinghelp_Btn_15] = '[Command] + [+]'
stringMap[strings.Mind_Usinghelp_Btn_16] = '放大導圖'
stringMap[strings.Mind_Usinghelp_Btn_17] = '[Ctrl] + [-]'
stringMap[strings.Mind_Usinghelp_Btn_18] = '[Command] + [-]'
stringMap[strings.Mind_Usinghelp_Btn_19] = '縮小導圖'
stringMap[strings.Mind_Usinghelp_Btn_20] = '[Ctrl] + [S]'
stringMap[strings.Mind_Usinghelp_Btn_21] = '[Command] + [S]'
stringMap[strings.Mind_Usinghelp_Btn_22] = '[Ctrl] + [Z]'
stringMap[strings.Mind_Usinghelp_Btn_23] = '[Command] + [Z]'
stringMap[strings.Mind_Usinghelp_Btn_24] = '[Ctrl] + [Shift] + [Z]'
stringMap[strings.Mind_Usinghelp_Btn_25] = '[Command] + [Shift] + [Z]'
stringMap[strings.Mind_Usinghelp_Btn_26] = '[Ctrl] + [B]'
stringMap[strings.Mind_Usinghelp_Btn_27] = '[Command] + [B]'
stringMap[strings.Mind_Usinghelp_Btn_28] = '退出編輯'

//提示文字
stringMap[strings.Message_Tips_Login_Success] = '登錄成功'
stringMap[strings.Message_Tips_Dlt_Success] = '刪除成功'
stringMap[strings.Message_Tips_Report_Success] = '舉報成功'
stringMap[strings.Message_Tips_Copy_Success] = '複製成功'
stringMap[strings.Message_Tips_Collction_Success] = '收藏成功'
stringMap[strings.Message_Tips_Avatar_Modification_Success] = '頭像修改成功'
stringMap[strings.Message_Tips_Signature_Modification_Success] = '簽名修改成功'
stringMap[strings.Message_Tips_Name_Modification_Success] = '暱稱修改成功'
stringMap[strings.Message_Tips_Modification_Success] = '修改成功'
stringMap[strings.Message_Tips_New_Profile_Success] = '新建文件夾成功'
stringMap[strings.Message_Tips_Mobile_Success] = '移動成功'
stringMap[strings.Message_Tips_Submit_Success] = '提交成功'
stringMap[strings.Message_Tips_Exchange_Success] = '兌換成功'
stringMap[strings.Message_Tips_Please_Code] = '請輸入激活碼'
stringMap[strings.Message_Tips_Agree_To_Terms] = '請同意隱私條款'
stringMap[strings.Message_Tips_Please_Sign_In] = '請登錄'
stringMap[strings.Message_Tips_Share_Success] = '分享成功'
stringMap[strings.Message_Tips_Create_Success] = '創建成功'
stringMap[strings.Message_Tips_Profile_Name_No_Null] = '文件夾名不能為空'
stringMap[strings.Message_Tips_Selecte_Success] = '搜索完成'
stringMap[strings.Message_Tips_Subscription_Success] = '訂閱成功'
stringMap[strings.Message_Tips_Unsubscribe_Success] = '取消訂閱成功'
stringMap[strings.Message_Tips_Related_Content_Not_Found] = '未找到相關內容'
stringMap[strings.Message_Tips_Max_Add_Class] = '最多只能添加'
stringMap[strings.Message_Tips_Registered_Success] = '註冊成功'
stringMap[strings.Message_Tips_Scan_Code_Login_Failed] = '掃碼登錄失敗'
stringMap[strings.Message_Tips_Share_Is_Null] = '分享鏈接不存在'
stringMap[strings.Message_Tips_Share_Link_Does_Not_Exist] = '上傳文件不能超過3MB'
stringMap[strings.Message_Tips_Get_Permission_Successfully] = '獲取權限成功'
stringMap[strings.Message_Tips_Membership_Required] = '需要會員'
stringMap[strings.Message_Tips_Save_Success] = '保存成功'
stringMap[strings.Message_Tips_Scan_Code_Login_Success] = '掃碼登錄成功'
stringMap[strings.Message_Tips_Style] = '個分類呢'

//大綱模式
stringMap[strings.Outline_Menu_Indent] = '縮進'
stringMap[strings.Outline_Menu_Decrease_Indent] = '減少縮進'

//幫助提示
stringMap[strings.Help_Background_Content] = '線條背景相關,線條背景相關,線條背景相關,線條背景相關,線條背景相關,線條背景相關,線條背景相關,線條背景相關,線條背景相關,'
stringMap[strings.Help_Mind_Content] = '可以根據喜好做不同佈局的導圖：雙嚮導圖、左側導圖、右側導圖、組織機構圖、雙向樹、左側樹、右側樹、表格'
stringMap[strings.Help_Share_Link_Content] = '分享鏈接可以以鏈接形式在微信、QQ、微博等渠道方式分享，好友可以查看內容'
stringMap[strings.Using_Templates] = '使用模板'

//邀請碼
stringMap[strings.User_Lolgin_Please_Enter_Invitation_Code] = '請輸入邀請碼'
stringMap[strings.User_Lolgin_Please_Correct_Enter_Invitation_Code] = '請輸入正確邀請碼'
stringMap[strings.User_Lolgin_Optional] = '選填'

//使用素材

stringMap[strings.Cannot_Add_To_Node_Background] = '不適用於節點背景'
stringMap[strings.Cannot_Add_To_Img_Background] = '不適用於文字背景'
stringMap[strings.Cannot_Add_To_Mind_Background] = '不適用於包裹線背景'
stringMap[strings.Mind_Edit_Node_Menu_Add_Encircle_Background] = '添加背景圖片'
stringMap[strings.Mind_Edit_Node_Menu_Delete_Encircle_Background] = '刪除背景圖片'
stringMap[strings.Mind_Add_Background_Image] = '插入背景圖'
stringMap[strings.Mind_Delete_Background_Image] = '刪除背景圖'

stringMap[strings.Permanent_Member] = '永久會員'
stringMap[strings.Insufficient_Storage_Space] = '存儲空間不足，升級會員增加空間。 '
stringMap[strings.Used_Space] = '存儲空間'
stringMap[strings.Current_Storage] = '當前存儲：'
stringMap[strings.Used_Space_Upgrade] = '存儲空間升級'
stringMap[strings.My_Mind_Is_Null_Tips] = 'Mind+腦圖&筆記 超級記憶術, 給思維插上翅膀 創作、展示、計劃、筆記'
stringMap[strings.Insufficient_Storage_Space_Tempalte_Tips] = '存儲空間不足，升級會員即可使用模板。 '

stringMap[strings.Group_Management] = '群組'
stringMap[strings.Save_Img_Fill_Full] = '鋪滿'
stringMap[strings.Save_Img_Center_Tilt] = '居中傾斜'
stringMap[strings.Save_Img_File_Full_Dark_Color] = '鋪滿（深色）'
stringMap[strings.Save_Img_Mind_Map_Watermarking] = 'Mind+思維導圖水印'
stringMap[strings.Save_Img_Add_Watermark_To_Protect_Community_Creation] = '添加水印保護知識創作：'
stringMap[strings.Save_Img_Watermark_Text] = '水印文字：'
stringMap[strings.Save_Img_Watermark_Logo] = '水印LOGO：'
stringMap[strings.Save_Img_Watermark_Style] = '水印樣式：'
stringMap[strings.Save_Img_Please_Input] = '請輸入'
stringMap[strings.Save_Img_Chop_Tips] = '開通會員即可享用水印權益'
stringMap[strings.Public_Header_Mindmap_Mind] = '導圖模式'
stringMap[strings.Copy_Mind_Map] = '複製導圖'
stringMap[strings.Font_Style_Bold] = '粗體'
stringMap[strings.Font_Style_Italics] = '斜體'
stringMap[strings.Font_Style_Delete_Line] = '刪除線'
stringMap[strings.Remove_Any] = '移除'

stringMap[strings.Mindmap_Review] = '導圖回顧'
stringMap[strings.Mindmap_Review_Recently_Updated_Content_10] = '加深印象增強記憶，高效梳理思路和信息'
stringMap[strings.Mindmap_Review_Content] = '導圖回顧即編輯過程回放，內容從中心主題出發依次播放;導圖回顧可以幫您進行系統盤點、分析、查找思路漏洞，回顧過程中可能會發現不一樣的靈感'
stringMap[strings.Mindmap_Review_Speed] = '速度'
stringMap[strings.Mindmap_Review_Word_Speed] = '文字速度'
stringMap[strings.Mindmap_Review_Background_Ratio] = '背景比例'
stringMap[strings.Mindmap_Review_Play_Order_Peer] = '優先播放同級內容'
stringMap[strings.Mindmap_Review_Current_Playback_Node_Magnification] = '當前播放節點放大倍數'
stringMap[strings.Mindmap_Review_Node_Playback_Order] = '節點播放順序'
stringMap[strings.Mindmap_Only_Play_Text] = '只播放文字'
stringMap[strings.Mindmap_Review_Tab_Lable] = "思維回顧模式"
stringMap[strings.Mindmap_Speech_Tab_Lable] = "演示模式"

stringMap[strings.Mind_Group_Picture_Upload] = '頭像上傳'
stringMap[strings.Mind_Group_Upload_From_Local] = '本地上傳'
stringMap[strings.Mind_Group_Mind_Mapping] = '思維導圖'
stringMap[strings.Mind_Group_Information] = '資料'

stringMap[strings.Mind_Group_Invite_New_Members] = '邀請新成員'
stringMap[strings.Mind_Group_Please_Enter_Your_Cell_Phone_Number] = '請輸入手機號'
stringMap[strings.Mind_Group_Cancel] = '取消'
stringMap[strings.Mind_Group_Confirm] = '確定'
stringMap[strings.Mind_Group_The_Phone_Number_Cannot_Be_Blank] = '手機號不能為空'
stringMap[strings.Mind_Group_Invite_The_Successful] = '邀請成功'


stringMap[strings.Mind_Group_Shared_Group_Membership] = "共享組成員"
stringMap[strings.Mind_Group_Maximum_Number_Of_Shared_Group_Members] = "共享組成員數量上限"
stringMap[strings.Mind_Group_Membership_Limit] = "成員上限"
stringMap[strings.Mind_Group_Current_Member_Limit] = "當前成員上限"
stringMap[strings.Mind_Group_Upgrade_Member_Limit] = "升級成員上限"
stringMap[strings.Mind_Group_Members_Of_The_Search] = "搜索成員"
stringMap[strings.Mind_Group_Creator] = "創建者"
stringMap[strings.Mind_Group_Administrator] = "管理員"
stringMap[strings.Mind_Group_Number_Of_People] = "人"
stringMap[strings.Mind_Group_Selected] = "已選擇"
stringMap[strings.Mind_Group_Member] = "成員"
stringMap[strings.Mind_Group_Check_All] = "全選"
stringMap[strings.Mind_Group_Remove] = "移除"
stringMap[strings.Mind_Group_Invite_New_Members] = "邀請新成員"
stringMap[strings.Mind_Group_Delete_Members] = "刪除成員"
stringMap[strings.Mind_Group_Please_Select_The_Member_To_Delete] = "請選擇要刪除的成員 "
stringMap[strings.Mind_Group_Make_Sure_You_Want_To_Remove_The_User_From_The_Group] = "確定要將用戶從本組中移除？"
stringMap[strings.Mind_Group_Add_As_Administrator] = "添加管理員"
stringMap[strings.Mind_Group_Accomplish] = "完成"
stringMap[strings.Mind_Group_Compile] = "編輯"
stringMap[strings.Mind_Group_Group_Owner] = "群主"
stringMap[strings.Mind_Group_Add_Admin] = "添加管理員"
stringMap[strings.Mind_Group_Group_ID] = "組id"
stringMap[strings.Mind_Group_Group_Number] = "組號"
stringMap[strings.Mind_Group_Name] = "名稱"
stringMap[strings.Mind_Group_Please_Enter_A_Shared_Group_Name] = "請輸入共享組名稱"
stringMap[strings.Mind_Group_Introduce] = "介紹"
stringMap[strings.Mind_Group_Please_Give_A_Brief_Introduction_To_Shared_Groups] = "請簡要介紹共享組"
stringMap[strings.Mind_Group_Announcement] = "公告"
stringMap[strings.Mind_Group_Shared_Group_Tag] = "共享組標籤"
stringMap[strings.Mind_Group_Custom_Tag] = "自定義標籤"
stringMap[strings.Mind_Group_Save] = "保存"
stringMap[strings.Mind_Group_The_Group_Name_Cannot_Be_Empty] = "組名不能為空"
stringMap[strings.Mind_Group_Establish] = "創建"
stringMap[strings.Mind_Group_Allows_Mapfolder_Creation] = "允許創建導圖/文件夾"
stringMap[strings.Mind_Group_Allows_Editing_Of_Maps] = "允許編輯導圖"
stringMap[strings.Mind_Group_Holder] = "所有人"
stringMap[strings.Mind_Group_Only_The_Administrator] = "僅管理員"
stringMap[strings.Mind_Group_Not_Allow] = "不允許"
stringMap[strings.Mind_Group_Allows_Map_Deletion] = "允許刪除導圖"
stringMap[strings.Mind_Group_Allows_Discussion_Boards_To_Post_Messages] = "允許討論區發消息"
stringMap[strings.Mind_Group_Export] = "導出"
stringMap[strings.Mind_Group_Allows_You_To_Save_Images_PDF] = "允許保存圖片/PDF"
stringMap[strings.Mind_Group_Open_Group_Watermark] = "開啟組水印"
stringMap[strings.Mind_Group_When_Turned_On_The_Mind_Map_Will_Be_Printed_With_A_Group_Logo] = "開啟後，思維導圖上會印上組標識"
stringMap[strings.Mind_Group_Allow_Saving_Maps] = "允許保存導圖"
stringMap[strings.Mind_Group_Save_It_To_My_Map] = "保存到我的導圖"
stringMap[strings.Mind_Group_Whether_Sharing_To_Storm_Is_Allowed] = "是否允許分享到風暴"
stringMap[strings.Mind_Group_Join_The_Way] = "加入方式"
stringMap[strings.Mind_Group_Whether_To_Invite_New_Members] = "是否允許邀請新成員"
stringMap[strings.Mind_Group_Whether_To_Allow_Search_Group_Numbers_To_Be_Added] = "是否允許搜索組號加入"
stringMap[strings.Mind_Group_Whether_The_Message_Needs_To_Be_Validated_When_Joining] = "加入時是否需要驗證消息"
stringMap[strings.Mind_Group_Shared_Group_Data] = "共享組資料"
stringMap[strings.Mind_Group_Authority_Management] = "權限管理"
stringMap[strings.Mind_Group_Quit_Sharing_Of_Group] = "退出共享組"
stringMap[strings.Mind_Group_Confirm_To_Exit_The_Shared_Group] = "是否確認退出共享組？"
stringMap[strings.Mind_Group_The_Dissolution_Of_Group] = "解散組"
stringMap[strings.Mind_Group_The_Files_Will_Be_Deleted_After_Disbanding_The_Group_Confirm_Disbanding] = "解散組後文件將刪除，確認解散？"
stringMap[strings.Mind_Group_Return_To_Home_Page] = "返回到首頁"
stringMap[strings.Mind_Group_Join_The_Group] = "加入組"
stringMap[strings.Mind_Group_Please_Enter_The_Group_Number] = "請輸入組號/組名搜索"
stringMap[strings.Mind_Group_Apply_To_Join] = "申請加入"
stringMap[strings.Mind_Group_Join] = "加入"
stringMap[strings.Mind_Group_Verification] = "驗證"
stringMap[strings.Mind_Group_Please_Enter_A_Validation_Message] = "請輸入驗證消息"
stringMap[strings.Mind_Group_The_Content_Cannot_Be_Empty] = "內容不能為空"
stringMap[strings.Mind_Group_Name] = "共享組名稱"
stringMap[strings.Mind_Group_Please_Enter_A_Shared_Group_Name] = "請輸入共享組名稱"
stringMap[strings.Mind_Group_Introduction] = "共享組介紹"
stringMap[strings.Mind_Group_Group_Indication] = "組號"
stringMap[strings.Mind_Group_The_Creator_Is_Lazy_And_Hasnt_Been_Introduced_Yet] = "創建者很懶，還沒有介紹哦~"
stringMap[strings.Mind_Group_Shared_Group_Announcement] = "共享組公告"
stringMap[strings.Mind_Group_No_Announcement] = "暫無公告"
stringMap[strings.Mind_Group_Click_On] = "點擊展開"
stringMap[strings.Mind_Group_Click_On_The_Hidden] = "點擊隱藏"
stringMap[strings.Mind_Group_Altogether] = "共"
stringMap[strings.Mind_Group_The_Group_Number_Was_Copied_Successfully] = "複製組號成功"
stringMap[strings.Mind_Group_Stick] = "置頂"
stringMap[strings.Mind_Group_There_Is_A_New_Content] = "有新內容"
stringMap[strings.Mind_Group_Cancel_The_Top] = "取消置頂"
stringMap[strings.Mind_Group_Setting_Up] = "設置"
stringMap[strings.Mind_Group_Usinghelp] = "使用幫助"
stringMap[strings.Mind_Group_Set_The_Message] = "組消息"
stringMap[strings.Mind_Group_Apply_To_Join] = "申請加入"
stringMap[strings.Mind_Group_Invite_To_Join] = "邀請加入"
stringMap[strings.Mind_Group_Consent] = "同意"
stringMap[strings.Mind_Group_Turn_Down] = "拒絕"
stringMap[strings.Mind_Group_Label] = "標籤"
stringMap[strings.Mind_Group_Please_Enter_A_Shared_Group_Label_Name] = "請輸入共享組標籤名稱"
stringMap[strings.Mind_Group_Invite_Friends_And_Colleagues_To_Create_And_Share_Community] = "邀請好友、同事共同創作、共享知識內容"
stringMap[strings.Mind_Group_Immediately_Create] = "立即創建"
stringMap[strings.Mind_Group_Immediately_To_Join] = "立即加入"

stringMap[strings.Currently_Selected] = "當前關注"
stringMap[strings.LabelManagement] ="標籤管理"
stringMap[strings.NoAttention] = "暫無關注"
stringMap[strings.Mindmap_Review_Play] = '播放'

stringMap[strings.Mind_Group_Joined] = "已加入"
stringMap[strings.Mind_Group_Download_App] = "下載APP"
stringMap[strings.Mind_Group_No_Content] = "暫無內容"
stringMap[strings.Mind_Group_Individual] = "個"
stringMap[strings.Mind_Group_Message_Area] = "留言區"
stringMap[strings.Mind_Group_No_Message] = "暫無留言，快去"
stringMap[strings.Mind_Group_Leave_Message] = "留言"
stringMap[strings.Mind_Group_Bar] = "吧"
stringMap[strings.Mind_Group_See_More] = "查看更多"
stringMap[strings.Mind_Group_Enter_Invitation_Code] = "輸入邀請碼"
stringMap[strings.Mind_Group_Apply_To_Join_Sharing_Group] = "申請加入共享組"
stringMap[strings.Reasons_For_Application_0_30_Words] = "申請理由（0-30字）"
stringMap[strings.Mind_Group_Send_Out] = "發送"
stringMap[strings.Mind_Group_Join_Successfully] = "加入成功"
stringMap[strings.Mind_Group_Application_Sent_Successfully] = "申請發送成功"

stringMap[strings.Mind_Group_Save_The_Mapping] = "保存導圖"
stringMap[strings.Mind_Group_Update_Record] = "修改記錄"

stringMap[strings.Mind_Edit_Page_Switch_Mapping] = "切換導圖"
stringMap[strings.Mind_Edit_Page_Mind_Retrospect] = "導圖回顧"
stringMap[strings.Mind_Edit_Page_A_User_Is_Already_Editing_The_Map] = "已有用戶在編輯導圖"
stringMap[strings.Mind_Edit_Page_Password] = "密碼"

stringMap[strings.Mind_Edit_Page_Sharing_Group] = "共享組"
stringMap[strings.Mind_MBean] = "M豆"
stringMap[strings.Mind_My_MBean] = "我的M豆"
stringMap[strings.Alipay] = "支付寶"
stringMap[strings.Alipay_Payment] = "支付寶支付"
stringMap[strings.Please_Pay_On_The_Payment_Page] = "請到支付頁面支付"
stringMap[strings.WeChat] = "微信"
stringMap[strings.Mind_Shooping_Total] = "合計"
stringMap[strings.Mind_Shooping_Confirm_Payment] = "確認支付"
stringMap[strings.Mind_Shooping_Redeem_Now] = "立即兌換"

stringMap[strings.Mind_Whether_Confirm_Payment_Purchase] = "是否確認支付購買"
stringMap[strings.Mind_Tips] = "提示"
stringMap[strings.Mind_Your_M_Bean_Insufficient_Whether_Go__Purchase_M_Bean] = "您的M豆不足，是否去購買M豆"

stringMap[strings.Mind_share_scan_code] = "Mind+掃碼"

stringMap[strings.Mind_Play_Resume_Tips_Do_You_Want_To_Go_Back_To_Where_It_Was_Last_Played] = "是否恢復到上次播放的地方？"
stringMap[strings.Mind_Play_Number_Of_Playable_Times_Remaining] = "剩餘可播放次數"

stringMap[strings.Group_Mind_Play_The_File_Will_Not_Exist] = "文件將徹底刪除，確認解散嗎"
stringMap[strings.Group_Mind_Up_One_Level] = "上一級"
stringMap[strings.Group_Mind_No_Documents] = "暫無文件"
stringMap[strings.Group_Mind_PaymentSuccessful] = "支付成功"

stringMap[strings.Mind_Edit_Page_Sharing_Group_introduce] = "人協同編輯，多端即時同步，共享文檔，提升團隊協作效率"

stringMap[strings.Mind_Edit_Subscript] = "下標"
stringMap[strings.Mind_Edit_Text_Location] = "文本位置"
stringMap[strings.Mind_Mind_Import_Into_A_Shared_Group] = "導入到共享組"

stringMap[strings.Standard_Colors] = "標準顏色"
stringMap[strings.More_Colors] = "更多顏色"
stringMap[strings.Reset_Password] = "重置密碼"
stringMap[strings.Account_Password_Login] = "賬號密碼登錄"
stringMap[strings.Authentication_code_Login_registration] = "驗證碼登錄/註冊"
stringMap[strings.One_End_Creation_Multi_End_Synchronization] = "一端創作 多端同步"
stringMap[strings.Rich_And_Diverse_Mapping_Structure] = "豐富多樣導圖結構"
stringMap[strings.Massive_And_ExquisiteTemplateLibrary] = "海量精美模板庫"
stringMap[strings.Map_Review_Play] = "導圖回顧-播放"
stringMap[strings.CommunityLearningAndSharingPlatform] = "知識學習與分享交流平台"

stringMap[strings.Mind_Mind_Upgrade_Storage_Space] = "升級存儲空間"
stringMap[strings.Mind_Mind_Let_Me_Comment_Too] = "我也來評論一下吧"
stringMap[strings.Mind_Mind_No_Message_Notifications_Are_Announced_At_This_Time] = "暫無消息通知"
stringMap[strings.Failed_To_Get_Recording_Device] = "獲取錄音設備失敗"

stringMap[strings.Mind_Association_Map] = "關聯導圖"
stringMap[strings.Mind_confirm_Association_Map] = "確定關聯該導圖？"
stringMap[strings.Mind_Association_Map_See] = "查看關聯導圖"
stringMap[strings.Mind_Association_Map_Delete] = "刪除關聯導圖"

stringMap[strings.Mind_Edit_Right_Menu_Correlation_Line] = "關聯線結構"
stringMap[strings.Mind_Edit_Right_Menu_Correlation_Line_Start] = "關聯線起點"
stringMap[strings.Mind_Edit_Right_Menu_Correlation_Line_End] = "關聯線終點"

stringMap[strings.Share_Group_To_Storm] = "是否分享組到風暴"
stringMap[strings.Apply_To_Join_Storm_Platform] = "在風暴平台，其他人看到併申請加入，和有共同知識愛好者一起頭腦風暴"
stringMap[strings.Pay_To_Goin] = "付費加入"
stringMap[strings.Pay_To_Goin_Need_Mbeans] = "加入時需要支付M豆"
stringMap[strings.Mbean_Can_Be_Realized] = "M豆可變現"

stringMap[strings.Mind_Visit] = "訪問"
stringMap[strings.Mind_Details] = "詳情"
stringMap[strings.Mind_See] = "查看"
stringMap[strings.Earn_Mbeans] = "賺取M豆"
stringMap[strings.Mind_Explain] = "說明"
stringMap[strings.Mind_Parenting] = "親子"
stringMap[strings.Mind_Internet] = "互聯網"
stringMap[strings.Mind_Psychology] = "心理學"
stringMap[strings.Mind_literature] = "文學"
stringMap[strings.Mind_Technology] = "科技"
stringMap[strings.Mind_Workplace] = "職場"
stringMap[strings.Mind_Life] = "生活"
stringMap[strings.Mind_Subject] = "學科"
stringMap[strings.Hot_Group] = "熱門組"
stringMap[strings.Open_Group] = "公開組"
stringMap[strings.Mind_Group_Used] = "已使用"

stringMap[strings.Mind_Click_Select] = "單擊選中"
stringMap[strings.Mind_Double_Click_Enter] = "雙擊進入"
stringMap[strings.Search_Shared_Groups] = "搜索共享組"
stringMap[strings.Search_Storm] = "搜索風暴"
stringMap[strings.Members_Save_Free] = "會員免費保存"
stringMap[strings.Bank_Card_Account_Name] = "請輸入您的銀行卡戶名"
stringMap[strings.Bank_Card_No] = "銀行卡號"
stringMap[strings.Please_Enter_Your_Bank_Card_No] = "請輸入您的銀行卡號"
stringMap[strings.Branch_Sub_Branch_Information] = "分行支行信息"
stringMap[strings.Branch_Sub_Branch_Example] = "例如：杭州分行城西支行"
stringMap[strings.Application_For_Withdrawal] = "申請提現"
stringMap[strings.Withdrawal_Amount] = "提現金額"
stringMap[strings.Withdrawal] = "提現"
stringMap[strings.Current_Revenue] = "當前收益"
stringMap[strings.Recharge] = "充值"
stringMap[strings.Current_Total_MBeans] = "當前M豆總數"
stringMap[strings.Withdrawal_Status] = "提現狀態"
stringMap[strings.Insufficient_Withdrawal_Amount] = "提現金額不足"
stringMap[strings.Load_More] = "加載更多"
stringMap[strings.Loading] = "加載中"
stringMap[strings.It_Is_Over] = "已經到底了"
stringMap[strings.Default_Label] = "默認標籤"
stringMap[strings.Selected_Label] = "已選標籤"
stringMap[strings.Current_Location] = "當前位置"
stringMap[strings.MGold_Introduction] = "M金是您通過Mind+賺取"
stringMap[strings.MGold_Details] = "您的付費云組，組內有知識導圖、學習資料等，用戶加入支付M豆，其中有50%會轉化為M金，M金大於50可提取"
stringMap[strings.Cloud_Group_Introduction] = "分享您的組，其他人可以在風暴頁查看到"
stringMap[strings.Cloud_Group_Details] = "您的付費云組，組內有知識導圖、學習資料等，用戶加入支付M豆，其中有50%會轉化為M金，M金大於50可提取"
stringMap[strings.Share_Storm_Introduction] = "分享您的導圖到風暴社區，讓更多的人向您學習、探討知識"
stringMap[strings.Share_Storm_Details] = "如果您開啟使用M豆保存導圖，其他用戶變可以使用您的知識導圖，你也將獲得50%的M豆，M豆可以用來購買會員、加入付費組、同樣可以支付使用其他人的導圖。"

stringMap[strings.Linked_Successfully] = "關聯成功"
stringMap[strings.Cancel_Archiving] = "取消歸檔"
stringMap[strings.Please_Select_Archive_Location] = "請選擇歸檔位置"
stringMap[strings.The_Search_Result_Is_Empty] = "搜索結果為空"

stringMap[strings.Customize_A_Style] = "非會員成員，只能自定義一個風格"
stringMap[strings.Adds_Current_Guide_Style_Library] = "將當前導圖風格添加到風格庫中"
stringMap[strings.Trial_Version] = "試用版"
stringMap[strings.Not_Supported_At_The_Moment] = "暫不支持"
stringMap[strings.Mind_File] = "文件"
stringMap[strings.Mind_Import] = "導入"

stringMap[strings.Mind_Edit_Insert_Video] = "插入視頻"
stringMap[strings.Mind_Edit_Drag_And_Drop_The_Video_Here] = "拖放視頻至此"
stringMap[strings.Message_Tips_Video_Upload_Time_Limit] = "上傳視頻時長不能超過60s"
stringMap[strings.Mind_Edit_Node_Menu_View_Video] = "查看視頻"
stringMap[strings.Mind_Edit_Node_Delete_View_Video] = "刪除視頻"
stringMap[strings.Message_Tips_Share_Video_Does_Not_Exist] = "上傳視頻不能超過10MB"
stringMap[strings.Mind_Upload_Video_Restrictions] = "僅支持上傳MP4格式的視頻,上限120s和10M大小"
stringMap[strings.Mind_Video_Size] = "視頻大小"
stringMap[strings.Mind_Edit_Left_Menu_Export_Word] = "導出Word"
stringMap[strings.Mind_Edit_Left_Menu_Export_TXT] = "導出TXT"
stringMap[strings.Mind_Edit_Left_Menu_Export_Save_Cloud] = "雲同步"
stringMap[strings.Mind_Edit_Left_Menu_Export_No_Save_Cloud] = "未同步"
stringMap[strings.Mind_Edit_Left_Menu_Export_Save_As] = "另存為"
stringMap[strings.Mind_Edit_Left_Menu_Export_Group_Not_Supporte_Save_As] = "共亯組不支持另存為，可以先講檔案保存到【最近創作】，再保存到本地"

stringMap[strings.Export_Failed] = "導出失敗"
stringMap[strings.Add_File] = "添加文件"
stringMap[strings.Drag_The_File_Directly_To_Upload] = "直接拖入文件，即可上傳"
stringMap[strings.Supported_Imported_File_Types] = "目前支持導入的文件類型：Word(僅支持.docx)、XMind、.txt"

stringMap[strings.Upgrade_To_Professional] = "升級到專業版"
stringMap[strings.Non_Members_Insert_Videos] = "非會員註冊24小時後才可插入視頻"
stringMap[strings.Upload_Failed] = "上傳失敗"
stringMap[strings.Upload_Failed_Title_Message] = "上傳失敗請點擊確認，刪除上傳失敗的遺留圖片"
stringMap[strings.Mind_Edit_Members_Use] = "會員使用"
stringMap[strings.Invitation_Code] = "邀請碼"
stringMap[strings.Student_Certification] = "學生認證"
stringMap[strings.I_Get_It] = "我會了，跳過引導"
stringMap[strings.Welcome_To_Mind] = "歡迎小主加入Mind+,讓我們開啟思維之旅吧！"
stringMap[strings.Mind_Before] = "先"
stringMap[strings.Try_A_Map] = "一個導圖試試看"
stringMap[strings.Click_Create] = "點擊創建"
stringMap[strings.Click_Create_A_Mind_Map] = "點擊創建一張思維導圖"
stringMap[strings.Mind_About] = "關於"
stringMap[strings.See_Checking_Data] = "查看個人信息"
stringMap[strings.Modify_Checking_Data] = "修改個人信息"
stringMap[strings.Mind_Shear] = "剪切"

stringMap[strings.Mind_Style_Empty] = "未收藏或自定義風格"
stringMap[strings.Mind_Branch_Free_Layout] = "分支自由佈局"
stringMap[strings.Mind_Branch_Free_Layout_explain] = "雙向結構和氣泡圖分支可以自由拖動佈局"
stringMap[strings.Mind_Free_Theme_Drag_Dnd_Snap] = "自由主題拖動吸附"
stringMap[strings.Select_A_Node] = "選中一個主題"
stringMap[strings.Create_Child_Node] = "創建子主題"
stringMap[strings.Add_A_Nice_Image_To_The_Node] = "接下來我們給子主題添加一個好看的"
stringMap[strings.Click_Icon_Panel] = "點擊圖標面板"
stringMap[strings.Select_An_Icon_You_Like] = "選擇一個你喜歡的圖標"
stringMap[strings.Replace_The_Mind_Map_With_A_New_One] = "接下來我們給思維導圖更換一個新的"
stringMap[strings.Select_The_Central_Theme] = "選中中心主題"
stringMap[strings.Switch_To_Canvas_Panel] = "切換到畫布面板"
stringMap[strings.Click_Layout] = "點擊佈局"
stringMap[strings.Choose_A_Layout_You_Like] = "選擇一個你喜歡的佈局"
stringMap[strings.Mind_Map_Save_Share] = "恭喜你已經成功做出了一張漂亮的思維導圖！我們把它保存下來分享給小伙伴吧~"
stringMap[strings.Click_Save_Picture] = "點擊保存圖片"
stringMap[strings.Click_OK] = "點擊確定"
stringMap[strings.Congratulations_On_Completing_The_Novice_Guide] = "恭喜你完成了新手引導~"
stringMap[strings.Upload_Succeeded] = "上傳成功"

stringMap[strings.Mind_Edit_Node_Menu_Quick_Style] = "快速樣式"
stringMap[strings.Mind_Extremely_Important] = "極其重要"
stringMap[strings.Mind_Important] = "重要"
stringMap[strings.Mind_Commonly] = "一般"
stringMap[strings.Mind_Ordinary] = "普通"
stringMap[strings.Member_Exclusive] = "該功能為會員專享，是否去升級會員？"
stringMap[strings.Member_Exclusive_Color_Scheme] = "該配色方案為會員專享，是否去升級會員？"
stringMap[strings.Mind_Enlarge] = "放大"
stringMap[strings.Mind_Narrow] = "縮小"
stringMap[strings.Mind_Center] = "居中"
stringMap[strings.Mind_Full_Screen] = "全屏"

stringMap[strings.Mind_Association_Node] = "關聯節點"
stringMap[strings.Mind_add_Resource] = "附件"
stringMap[strings.Mind_Float_Explain] = "註釋"

stringMap[strings.Mind_Resource_See] ="查看附件"
stringMap[strings.Mind_Resource_Delete] ="刪除附件"
stringMap[strings.Mind_Edit_Drag_And_Drop_The_explain_Here] = "拖放文件至此"
stringMap[strings.Mind_Edit_choose_explain] = "選擇文件"
stringMap[strings.Message_Tips_Share_Explain_Does_Not_Exist] = "上傳文件不能超過20MB"

stringMap[strings.Please_Enter_Mind_Password] = "請輸入4～8比特密碼"
stringMap[strings.Please_Enter_Mind_Password_For_Unlock] = "請輸入密碼"
stringMap[strings.Please_Enter_Mind_Password_Member_Tips] = "此功能是會員功能"
stringMap[strings.Please_Enter_Mind_Password_Remove_Tips] = "已經移除檔案密碼"
stringMap[strings.Please_Enter_Mind_Password_Length_Short] = "密碼位數不够，請輸入4倒8比特密碼"
stringMap[strings.Please_Enter_Mind_Password_Length_Long] = "密碼太長，請輸入4倒8比特密碼"
stringMap[strings.Please_Enter_Mind_Password_Success] = "密碼設定成功"
stringMap[strings.Please_Enter_Mind_Password_Format_Error] = "密碼格式錯誤"
stringMap[strings.Mind_Load_Data_Error] = "數據加載出錯"
stringMap[strings.Please_Enter_Mind_Password_Error] = "密碼錯誤"
stringMap[strings.Please_Enter_Mind_Password_For_Owner] = "只有該導圖創建者可以設置密碼"

stringMap[strings.Mind_Edit_Right_Menu_Node_Full_Shape] = '填充样式'
stringMap[strings.Mind_Extremely_DrawOblique] = "纹理"
stringMap[strings.Mind_Extremely_Gradient] = "渐变"
stringMap[strings.Mind_Extremely_Shadow] = "阴影"
stringMap[strings.Mind_Style_Tight_Mode_Label] = "紧促型布局"
stringMap[strings.Mind_Style_Same_Level_Alignment_Label] = "同级主题对齐"
stringMap[strings.Mind_Style_Node_Overlap] = "主題層疊"
stringMap[strings.Mind_Style_To_Peer] = "使用到同级"
stringMap[strings.Mind_Style_To_All_Son_Subject] = "使用到所有子主题"
stringMap[strings.Mind_Edit_Node_Menu_Save_Picture] = "图片另存为"
stringMap[strings.Mind_Edit_Order_Label] = '编号'
stringMap[strings.Mind_Edit_Order_1_Label] = '1, 2, 3, ...'
stringMap[strings.Mind_Edit_Order_2_Label] = 'Ⅰ, Ⅱ, Ⅲ, ...'
stringMap[strings.Mind_Edit_Order_3_Label] = 'ⅰ, ⅱ, ⅲ, ...'
stringMap[strings.Mind_Edit_Order_4_Label] = 'A, B, C, ...'
stringMap[strings.Mind_Edit_Order_5_Label] = 'a, b, c, ...'
stringMap[strings.Mind_Edit_Order_6_Label] = '(1),(2),(3), ...'
stringMap[strings.Mind_Edit_Order_7_Label] = '①, ②, ③, ...'
stringMap[strings.Mind_Edit_Order_8_Label] = '一, 二, 三, ...'
stringMap[strings.Mind_Edit_Order_Level_1_Label] = '编号首层'
stringMap[strings.Mind_Edit_Order_Level_2_Label] = '编号前两层'
stringMap[strings.Mind_Edit_Order_Level_3_Label] = '编号前三层'
stringMap[strings.Mind_Edit_Order_Level_4_Label] = '编号前四层'
stringMap[strings.Mind_Edit_Order_Remove_Label] = '移除编号'
stringMap[strings.Mind_Edit_Open_Hidde_Title] = '顯示層級數'
stringMap[strings.Mind_Edit_Open_Hidde_1] = '顯示1層'
stringMap[strings.Mind_Edit_Open_Hidde_2] = '顯示2層'
stringMap[strings.Mind_Edit_Open_Hidde_3] = '顯示3層'
stringMap[strings.Mind_Edit_Open_Hidde_4] = '顯示4層'
stringMap[strings.Mind_Edit_Open_Hidde_5] = '顯示5層'
stringMap[strings.Mind_Edit_Open_Hidde_All] = '顯示所有層'

stringMap[strings.Mind_Edit_Node_Menu_Ai_Label] = "Ai助手-GPT"
stringMap[strings.Mind_Edit_Ai_Title] = 'Ai助手'
stringMap[strings.Mind_Ai_Empty_Tips_1_Label] = '輸入你想要的任何問題，Ai將給出結果'
stringMap[strings.Mind_Ai_Empty_Tips_2_Label] = '請合理文明使用Ai助手'
stringMap[strings.Mind_Ai_To_Map_Label] = '加入脳図'
stringMap[strings.Mind_Copy_Cuccess_Label] = '成功複製'
stringMap[strings.Mind_Ai_Input_Hint_Label] = '請輸入問題，Ai將給出結果…'
stringMap[strings.Mind_Ai_Submit_Label] = '提交'
stringMap[strings.Mind_Ai_Exceeding_Limit_Label] = '很抱歉，單日使用次數太多，請明天再使用'
stringMap[strings.Mind_Ai_To_Senior_Member_Label] = '今日使用次數已用完，永久會員可獲得更多單日使用次數'
stringMap[strings.Mind_Ai_To_Member_Label] = '免費次數已用完，會員可獲得更多單日使用次數'
stringMap[strings.Mind_Ai_Wait_0_Label] = 'AI加載中'
stringMap[strings.Mind_Ai_Wait_1_Label] = 'Ai正在收集資訊'
stringMap[strings.Mind_Ai_Wait_2_Label] = 'Ai正在整理優化資訊'
stringMap[strings.Mind_Ai_Wait_3_Label] = '請請耐心等待，你的問題比較專業'
stringMap[strings.Mind_Ai_Wait_4_Label] = '請您耐心等待'
stringMap[strings.Mind_Ai_Wait_5_Label] = '已經在返回的道路上'
stringMap[strings.Mind_Ai_Submit_Content_Psuffix_Label] = '請以步驟管道給我'
stringMap[strings.Mind_Ai_Service_Upgraded_Label] = 'Ai服務正在陞級，請稍後重試'
stringMap[strings.Mind_Node_To_Card_Label] = '变为卡片'     
stringMap[strings.My_Mind_Left_Right_Focus_Modol] = "发散思维-头脑风暴模型"
stringMap[strings.My_Mind_Left_Right_Free_Modol] = "灵动-自由流线模型"
stringMap[strings.My_Mind_Left_Right_Neat_Modol] = "笔记、小说-梳理模型"
stringMap[strings.My_Mind_Left_Right_Fresh_Modol] = "全线条-清新模型"
stringMap[strings.My_Mind_Tree_Left_Right_Modol] = "[父子]层级关系模型"
stringMap[strings.My_Mind_Left_Right_Outline_Modol] = "便捷、快速、随手记"
stringMap[strings.My_Mind_Fish_Modol] = "因果分析模型"
stringMap[strings.My_Mind_Bridge_Modol] = "类比相似模型"
stringMap[strings.My_Mind_Brackets_Right_Modol] = "整体和局部[总分]关系模型"
stringMap[strings.My_Mind_Radiate_Modol] = "平行放射-关系聚合模型"
stringMap[strings.My_Mind_Right_Modol] = "右侧拓展分析模型"
stringMap[strings.My_Mind_Left_Modol] = "左侧拓展分析模型"
stringMap[strings.My_Mind_Form_Modol] = "对比、罗列分块分析模型"
stringMap[strings.My_Mind_Horizontal_Right_Modol] = "具有前后事件关系模型"
stringMap[strings.My_Mind_Tree_Right_Modol] = "竖屏、顺序性、全局性模型"
stringMap[strings.My_Mind_Bottom_Modol] = "组织架构、平行知识模型"
stringMap[strings.My_Mind_Top_Modol] = "事物分析模型"
stringMap[strings.My_Mind_Bubble_Modol] = "分析事物属性、关系模型"
stringMap[strings.My_Mind_Circular_Modol] = "分类、归纳模型"
stringMap[strings.My_Mind_Double_Bubble_Modol] = "对比共性和特性"
stringMap[strings.My_Mind_Time_Vertical_Modol] = "竖向先后顺序事件模型"
stringMap[strings.My_Mind_Time_Horizontal_Modol] = "横向先后顺序事件模型"
stringMap[strings.My_Mind_Time_S_Horizontal_Modol] = "大信息量、事件模型"
stringMap[strings.My_Mind_Left_Right_Card_Modol] = "卡片模型"
stringMap[strings.Mind_Menu_Edit_Title] = "编辑标题"

stringMap[strings.Global_Text_Find] = "查找"
stringMap[strings.Global_Text_Replace] = "替換"
stringMap[strings.Global_Text_Replace_All] = "全部替換"
stringMap[strings.Global_Text_Find_Empty] = "無內容"
stringMap[strings.Global_Text_Find_Result] = "第&项, 共@项"

stringMap[strings.Mind_Fast_Style_Rainbow_Label] = "活力"
stringMap[strings.Mind_Fast_Style_Solid_Label] = "純真"
stringMap[strings.Mind_Import_File] = "導入檔案"

stringMap[strings.Mind_Add_Font_Label] = "+ 添加本地字體:ttf、woff..."
stringMap[strings.Mind_Import_Font_Web_Tips] = "請到官網下載電腦用戶端，使用該功能，https://mindyushu.com"
stringMap[strings.Mind_Import_Font_Upgrade_Label] = "免費試用版只能添加1個外部字體，陞級到Pro用戶，可添加更多外部字體以及所有專業版功能。"

stringMap[strings.Mind_Flow_Chart_Map] = "流程图"
stringMap[strings.Mind_Shape_Corner_Text] = "流程"
stringMap[strings.Mind_Shape_Start_Text] = "开始"
stringMap[strings.Mind_Shape_End_Text] = "结束"
stringMap[strings.Mind_Shape_Determine_Text] = "判定"
stringMap[strings.Mind_Shape_Ellipse_3D_Text] = "数据库"
stringMap[strings.Mind_Shape_Cube_Text] = "科学"
stringMap[strings.Mind_Shape_Form_Text] = "文档"
stringMap[strings.Mind_Shape_Corner_Right_Text] = "卡片"
stringMap[strings.Mind_Shape_Book_Text] = "书本"
stringMap[strings.Mind_Shape_Text] = "文本"

stringMap[strings.Mind_Map_Picture_Paddinng_H_Text] = "横行边距"
stringMap[strings.Mind_Map_Picture_Paddinng_V_Text] = "竖向边距"

stringMap[strings.Mind_Map_Create_Map_Info_Text] = "選擇多種思維結構框架"
stringMap[strings.Mind_Map_Create_File_Info_Text] = "快捷管理你的心智圖"
stringMap[strings.Mind_Map_Open_File_Info_Text] = "打開你設備上的檔案"
module.exports = stringMap;